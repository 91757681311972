import {
    SUBDEAL_API_START_SEARCH,
    SUBDEAL_API_RESPONSE_COMPLETE,
    SUBDEAL_API_CHANGES_KEY_DATA,
    SUBDEAL_API_EDIT_COMPLETE,
    SET_SUBDEAL_ON_INTIAL_STATE,
    SUBDEAL_EDIT_DISCARD
} from '../../consts/actionTypes';
import { mapSubdeal } from '../../consts/mapSubdealBasic';
const initialState = {
    urlConfig: {},
    listResponse: null,
    basic: {},
    front: [],
    back: [],
    dataChange: {},
};

let temp = {
    urlConfig: {},
    listResponse: null,
    basic: {},
    front: [],
    back: [],
    dataChange: {},
}
export default function (state = initialState, action) {
    switch (action.type) {
        case SUBDEAL_API_START_SEARCH:
            return {
                ...state,
                urlConfig: { associate: action.associate, subdeal: action.subdeal },
            };
        case SUBDEAL_API_RESPONSE_COMPLETE:
            const response = action.results.data[0];
            const createArrayObjct = (keyName) => {
                let arrayObject = [];
                let index = 1;
                while (true) {
                    let key = `${keyName}${index}`;
                    if (response.hasOwnProperty(`${key}D`)) {
                        let object = {
                            description: response[`${key}D`],
                            coste: response[`${key}C`],
                            price: response[`${key}P`],
                        };
                        arrayObject = [...arrayObject, object];
                        index++;
                    } else {
                        break;
                    }
                }
                return arrayObject;
            };
            const createBasicObject = () => {
                let newObj = {};
                for (let i in response) {
                    if (i.length < 6 || i.length > 8) {
                        newObj = { ...newObj, [i]: response[i] };
                    }
                }
                let newobj2 = {};
                for (let o in newObj) {
                    newobj2 = { ...newobj2, [o]: { ...mapSubdeal[o], value: newObj[o] } };
                }
                console.log(newobj2);
                return newobj2;
            };
            temp = {
                ...state,
                listResponse: { ...response },
                basic: createBasicObject(),
                back: createArrayObjct('BACK'),
                front: createArrayObjct('FRONT'),
            }
            return temp;
        case SUBDEAL_API_CHANGES_KEY_DATA:
            const keyObject = action.keyObject;
            const targetName = action.targetName;
            const targetValue = action.targetValue;
            const indexArray = action.indexArray;
            let basicTemp = { ...state.basic };
            let backTemp = [...state.back];
            let frontTemp = [...state.front];
            let listResponseTemp = { ...state.listResponse };
            let dataChangeTemp = { ...state.dataChange };
            const createArrayTemp = (arrayBase) => {
                let arrayTemp = [];
                let objectArrayTemp = {};
                objectArrayTemp = { ...arrayBase[indexArray], [targetName]: targetValue };
                for (const i in arrayBase) {
                    if (i != indexArray) {
                        arrayTemp = [...arrayTemp, arrayBase[i]];
                    } else {
                        arrayTemp = [...arrayTemp, objectArrayTemp];
                    }
                }
                return arrayTemp;
            };
            const onChageLisReponse = (keyname) => {
                let letter = targetName.charAt(0).toUpperCase();
                dataChangeTemp = {
                    ...dataChangeTemp,
                    [`${keyname}${indexArray + 1}${letter}`]: targetValue,
                };
                listResponseTemp = {
                    ...listResponseTemp,
                    [`${keyname}${indexArray + 1}${letter}`]: targetValue,
                };
            };
            switch (keyObject) {
                case 0:
                    frontTemp = createArrayTemp(frontTemp);
                    onChageLisReponse('FRONT');
                    break;
                case 1:
                    backTemp = createArrayTemp(backTemp);
                    onChageLisReponse('BACK');
                    break;
                case 2:
                    basicTemp = {
                        ...basicTemp,
                        [targetName]: { ...basicTemp[targetName], value: targetValue },
                    };
                    dataChangeTemp = { ...dataChangeTemp, [targetName]: targetValue };
                    listResponseTemp = { ...listResponseTemp, [targetName]: targetValue };
                    break;
            }

            return {
                ...state,
                basic: { ...basicTemp },
                back: [...backTemp],
                front: [...frontTemp],
                listResponse: { ...listResponseTemp },
                dataChange: { ...dataChangeTemp },
            };
        case SUBDEAL_API_EDIT_COMPLETE:
            return {
                ...state,
                listResponse: null,
                basic: {},
                back: [],
                front: [],
            };
        case SET_SUBDEAL_ON_INTIAL_STATE:
            return {
                ...state,
                urlConfig: {},
                listResponse: null,
                basic: {},
                front: [],
                back: [],
                dataChange: {},
            };
        case SUBDEAL_EDIT_DISCARD:
            return {
                ...state,
                basic: { ...temp.basic },
                back: [...temp.back],
                front: [...temp.front],
                listResponse: { ...temp.listResponse },
                dataChange: { ...temp.dataChange },
            };
        default:
            return { ...state };
    }
}
