import React from 'react';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import { Card, TextField, IconButton } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

const View = (props) => {
    const {
        addCar,
        handleChangeAddCar,
        onAddCar,
        handleChangeUnit,
        onEdit,
        onDelete,
        listUnitAdjustment,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card className={classes.cardAdd}>
                <TextField
                    label='Description'
                    name='description'
                    size='small'
                    value={addCar?.description}
                    onChange={handleChangeAddCar}
                    variant='outlined'
                    style={{ marginRight: 5 }}
                />
                <TextField
                    label='New'
                    name='new'
                    size='small'
                    value={addCar?.new}
                    onChange={handleChangeAddCar}
                    variant='outlined'
                    style={{ marginRight: 5, maxWidth: 50 }}
                />

                <TextField
                    label='Used'
                    name='used'
                    size='small'
                    value={addCar?.used}
                    onChange={handleChangeAddCar}
                    variant='outlined'
                    style={{ marginRight: 5, maxWidth: 50 }}
                />
                <IconButton color='primary' variant='contained' onClick={onAddCar}>
                    <AddCircleIcon className={classes.Icons} />
                </IconButton>
            </Card>
            {listUnitAdjustment?.map((item, index) => {
                return (
                    <Card className={classes.cardAdd} key={index}>
                        <TextField
                            label='Description'
                            name='description'
                            size='small'
                            value={item?.description}
                            onChange={(e) => handleChangeUnit(e, index)}
                            variant='outlined'
                            style={{ marginRight: 5 }}
                        />
                        <TextField
                            label='New'
                            name='new'
                            size='small'
                            value={item?.new}
                            onChange={(e) => handleChangeUnit(e, index)}
                            variant='outlined'
                            style={{ marginRight: 5, maxWidth: 50 }}
                        />

                        <TextField
                            label='Used'
                            name='used'
                            size='small'
                            value={item?.used}
                            onChange={(e) => handleChangeUnit(e, index)}
                            variant='outlined'
                            style={{ marginRight: 5, maxWidth: 50 }}
                        />
                        <IconButton
                            color='primary'
                            variant='contained'
                            onClick={() => onEdit(index)}
                        >
                            <Edit className={classes.Icons} />
                        </IconButton>
                        <IconButton
                            color='primary'
                            variant='contained'
                            onClick={() => onDelete(index)}
                        >
                            <DeleteIcon className={classes.Icons} />
                        </IconButton>
                    </Card>
                );
            })}
        </div>
    );
};

View.propTypes = {
    addCar: PropTypes.object.isRequired,
    handleChangeAddCar: PropTypes.func.isRequired,
    onAddCar: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    listUnitAdjustment: PropTypes.array.isRequired,
    handleChangeUnit: PropTypes.func.isRequired,
};

export default View;
