import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        boxSizing:'border-box'
    },
    card: {
        height: '100%',
    },
    content: {
        maxHeight: '100%',
        overflow: 'Auto',
    },
    input:{
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    btnContainer:{
        marginLeft: '1rem',
        display:"flex",
        alignItems:"center",
        gap:"1rem"
    },
    dealContainer:{
        margin: "1rem 0",
        padding:'1rem'
    },
    font14:{
        fontSize:"14px",
        padding:"0.5rem 0",
        whiteSpace:"nowrap",
    }
}));
