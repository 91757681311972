import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { Divider, Drawer, Switch, FormControlLabel } from '@material-ui/core';
import { pages, messageButtons } from './pages';
import { Profile, SidebarNav, MessageMenuButtons } from './components';

const Sidebar = (props) => {
    const { open, variant, onClose, className, fixedSidebar, handleFixedSidebar, size, ...rest } =
        props;
    const classes = useStyles();

    const [openNested, setOpenNested] = useState('');
    const handleNestedClick = (pageTitle) => {
        setOpenNested((prev) => (prev === pageTitle ? '' : pageTitle));
    };

    return (
        <Drawer
            anchor='left'
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div {...rest} className={clsx(classes.root, className)}>
                <MessageMenuButtons
                    onClose={onClose}
                    handleNestedClick={handleNestedClick}
                    openNested={openNested}
                />
                <SidebarNav
                    className={classes.nav}
                    pages={pages}
                    onClose={onClose}
                    handleNestedClick={handleNestedClick}
                    openNested={openNested}
                />
                {size ? (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={fixedSidebar}
                                onChange={(e) => {
                                    handleFixedSidebar(e);
                                }}
                                color='primary'
                                size='small'
                            />
                        }
                        labelPlacement='start'
                        label='Fixed Menu'
                    />
                ) : (
                    <div />
                )}
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
