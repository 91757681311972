import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    field: {
        display: 'flex',
        flexDirection: 'row',
    },
    fabClose: {
        margin: 0,
        top: 5,
        right: 20,
        bottom: 'auto',
        left: 'auto',
        position: 'fixed',
        zIndex: 3,
    },
    expaindetail: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    fabPrint: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 5,
        left: 'auto',
        position: 'fixed',
        zIndex: 1,
    },
}));
