import { get } from 'lodash';
import {
    ASSOCIATE_LIST_COMPLETE,
    ASSOCIATE_LIST_ERROR,
    ASSOCIATE_REGISTER_COMPLETE,
    ASSOCIATE_DELETE_COMPLETE,
    ASSOCIATE_DELETE_ERROR,
    ASSOCIATE_CONF_CARD_ASSOCIATE,
    ASSOCIATE_ASSOCIATE,
    ASSOCIATE_LIST_BY_TYPE_COMPLETE,
} from '../../consts/actionTypes';

const associateEmpty = {
    name: '',
    type: '',
    dms: '',
    enabled: true,
    STORE: '',
};
const initialState = {
    listResponse: [],
    listNameCode: [[], [], []],
    msgSnackBar: null,
    confCardAssociate: {
        cardTitle: 'Create Associate',
        buttonCreate: 'Create',
        textFildDms: false,
    },
    associate: { ...associateEmpty },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ASSOCIATE_LIST_COMPLETE:
            let list = get(action, 'results.data');
            list.sort(function (a, b) {
                var n = a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
                return n === 0 && a.name !== b.name ? b.name.localeCompare(a.name) : n;
            });

            return { ...state, listResponse: list };
        case ASSOCIATE_LIST_BY_TYPE_COMPLETE:
            /*
              const alphabeticOrder = (listOrder) => {
                listOrder.sort(function (a, b) {
                    var n = a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
                    return n === 0 && a.name !== b.name ? b.name.localeCompare(a.name) : n;
                });
                return listOrder;
            };
            */

            const DMSNumberOrder = (listOrder) => {
                listOrder.sort(function (a, b) {
                    return parseInt(a.dms_number) - parseInt(b.dms_number);
                });
                return listOrder;
            };

            let listSeller = DMSNumberOrder(action.resultsSeller.data);
            let listFim = DMSNumberOrder(action.resultsFim.data);
            const listTM = DMSNumberOrder(action.resultsTM.data);

            const associateJoinNameCode = (associate) => {
                let list = [];
                let listType = [];
                let listObj = {};
                for (let type of associate) {
                    for (let objs of type) {
                        listObj = {
                            ...objs,
                            name: `${objs.dms_number} - ${objs.name}`,
                            code: objs.dms_number,
                        };
                        listType.push(listObj);
                    }
                    list.push(listType);
                    listType = [];
                }
                return list;
            };

            return {
                ...state,
                listResponse: [listSeller, listFim, listTM],
                listNameCode: associateJoinNameCode([listSeller, listFim, listTM]),
            };
        case ASSOCIATE_LIST_ERROR:
            return { ...state, listResponse: null };
        case ASSOCIATE_REGISTER_COMPLETE:
            return {
                ...state,
                listResponse: null,
                confCardAssociate: {
                    cardTitle: 'Create Associate',
                    buttonCreate: 'Create',
                    textFildDms: false,
                },
                associate: { ...associateEmpty },
            };
        case ASSOCIATE_DELETE_COMPLETE:
            return {
                ...state,
                listResponse: null,
                associate: { ...associateEmpty },
            };
        case ASSOCIATE_DELETE_ERROR:
            return { ...state, idItemSalected: null };
        case ASSOCIATE_CONF_CARD_ASSOCIATE:
            return { ...state, confCardAssociate: get(action, 'payload') };
        case ASSOCIATE_ASSOCIATE:
            return { ...state, associate: get(action, 'payload') };

        default:
            return { ...state };
    }
}
