import { get } from 'lodash';
import { SIGN_IN_COMPLETE, LOGOUT_COMPLETE } from '../../consts/actionTypes';

const initialState = {
    token: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SIGN_IN_COMPLETE:
            return {
                ...state,
                token: get(action, 'results.data.access_token'),
            };
        case LOGOUT_COMPLETE:
            return {
                ...state,
                token: null,
            };
        default:
            return { ...state };
    }
}
