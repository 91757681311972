import { put, call, takeLatest } from 'redux-saga/effects';
import {
    SIGN_IN_START,
    SIGN_IN_COMPLETE,
    ASSOCIATE_NAME_LIST,
    PAYPLAN_LIST_START,
    TERM_LIST_START,
    LOGOUT_COMPLETE,
    LOGOUT_START,
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { rmLocalStorage, setLocalStorage, REFRESH_TOKEN } from '../../consts/localStorage';
import { authCall, apiCall } from '../api';
import { AUTH, ASSOCIATE_LIST_DROP_DOWN } from '../../consts/urls';

export function* signIn({ payload }) {
    try {
        let configHeaders = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const results = yield call(authCall, AUTH, payload, configHeaders, 'POST');
        yield setLocalStorage(results.data.access_token);
        yield setLocalStorage(results.data.refresh_token, REFRESH_TOKEN);
        yield put({ type: SIGN_IN_COMPLETE, results });

        // Don't know about this
        const associateNameList = yield call(
            apiCall,
            ASSOCIATE_LIST_DROP_DOWN,
            null,
            results.data.access_token,
            'GET'
        );
        yield put({ type: ASSOCIATE_NAME_LIST, associateNameList });
    } catch (error) {
        const messageError = error.response.data.error;
        yield put(
            snackBarAction({
                open: true,
                msg: messageError,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* logout() {
    yield rmLocalStorage();
    yield put({ type: LOGOUT_COMPLETE });
}

export default function* login() {
    yield takeLatest(SIGN_IN_START, signIn);
    yield takeLatest(LOGOUT_START, logout);
}
