import React, { useState, useEffect } from 'react';
import View from './view';
import {
    listResponseSelector,
    listNameCodeAssociateSelector,
    isLoading,
} from '../../redux/selectors';
import { isLoadingAction } from '../../redux/actions/utilitiesAction';
import { createTermAction, updateTermAction, listTermAction } from '../../redux/actions/termAction';
import { associateListByType } from '../../redux/actions/associateAction';
import { useSelector, useDispatch } from 'react-redux';
import { listDataAction } from '../../redux/actions/payPlanAction';

export default () => {
    const listTerm = useSelector((state) => listResponseSelector(state, 'termReducer'));
    const listPayPlan = useSelector((state) => listResponseSelector(state, 'payPlanReducer'));
    const associate = useSelector((state) => listNameCodeAssociateSelector(state).flat());
    const isLoadingResponse = useSelector((state) => isLoading(state));
    const dispatch = useDispatch();

    console.log(listTerm);

    useEffect(() => {
        dispatch(listTermAction());
    }, [listTerm?.length]);

    useEffect(() => {
        if (
            !checkEmptyArr(associate[0]) &&
            !checkEmptyArr(associate[1]) &&
            !checkEmptyArr(associate[2])
        )
            dispatch(associateListByType());
        dispatch(listDataAction());
    }, []);

    const checkEmptyArr = (arr) => (arr?.length ? true : false);

    const onEdit = (data) => {
        dispatch(isLoadingAction(true));
        const { _id, ...withoutId } = data;
        dispatch(updateTermAction(_id, withoutId));
    };
    const onAdd = (data) => {
        dispatch(isLoadingAction(true));
        dispatch(createTermAction(data));
    };

    return (
        <View
            listTerm={listTerm}
            listPayPlan={listPayPlan}
            associate={associate}
            onEdit={onEdit}
            isLoadingResponse={isLoadingResponse}
            onAdd={onAdd}
        />
    );
};
