import { IS_LOADING, SNACKBAR, DIALOGMESSAGE, ID_ITEM_SELECTED } from '../../consts/actionTypes';

export const isLoadingAction = (isloading) => ({
    type: IS_LOADING,
    isloading,
});
export const snackBarAction = (snackBar) => ({
    type: SNACKBAR,
    snackBar,
});
export const dialogMessageAction = (payload) => ({
    type: DIALOGMESSAGE,
    payload,
});
export const idItemSeletedAction = (payload) => ({
    type: ID_ITEM_SELECTED,
    payload,
});
