import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    SUBDEAL_API_START_SEARCH,
    SUBDEAL_API_RESPONSE_COMPLETE,
    SUBDEAL_API_EDIT_START,
    SUBDEAL_API_EDIT_COMPLETE,
    SUBDEAL_API_DELETE_START,
    SUBDEAL_API_REVERSAL_SEARCH_START,
    SUBDEAL_API_REVERSAL_SEARCH_ERROR,
} from '../../consts/actionTypes';

import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { SUBDEAL_API, REVERSAL_ALL } from '../../consts/urls';
import {
    token,
    urlConfigSelector,
    dataChangeSelector,
    dmsCurrentWashoutSelector,
    dateCurrentWashoutSelector,
    typeAssoCurrentWashoutSelector,
} from '../selectors';
import { washoutSearch } from '../actions/washoutAction';

function* searchCurrent() {
    const dmsCurrent = yield select(dmsCurrentWashoutSelector);
    const dateCurrent = yield select(dateCurrentWashoutSelector);
    const typeAssoCurrent = yield select(typeAssoCurrentWashoutSelector);
    yield put(washoutSearch(dmsCurrent, dateCurrent, typeAssoCurrent));
}
export function* search({ associate, subdeal }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${SUBDEAL_API}/${associate}/${subdeal}`,
            null,
            responseToken,
            'GET'
        );

        yield put({ type: SUBDEAL_API_RESPONSE_COMPLETE, results });

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        console.log(errorResponse);
        /* yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse })
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error Load List: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
    }
    yield put(isLoadingAction(false));
}
export function* edit() {
    try {
        const responseToken = yield select(token);
        const urlconf = yield select(urlConfigSelector);
        const data = yield select(dataChangeSelector);
        const results = yield call(
            apiCall,
            `${SUBDEAL_API}/${urlconf.associate}/${urlconf.subdeal}`,
            data,
            responseToken,
            'PUT'
        );

        yield put({ type: SUBDEAL_API_EDIT_COMPLETE, results });
        yield* searchCurrent();
        //  console.log(results)
        /*  yield put(snackBarAction(
              {
                  open: true,
                  msg: results.data.message,
                  type: 'success'
              }
          ));*/
    } catch (error) {
        const errorResponse = error.response;
        console.log(errorResponse);
        /* yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse })
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error Load List: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
        yield put(isLoadingAction(false));
    }
    // yield put(isLoadingAction(false));
}
export function* deleteSubDeal({ associate, subdeal }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${SUBDEAL_API}/${associate}/${subdeal}`,
            null,
            responseToken,
            'DELETE'
        );
        console.log(results);
        // yield put({ type: SUBDEAL_API_DELETE_COMPLETE, results })

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* searchCurrent();
    } catch (error) {
        const errorResponse = error.response;
        console.log(errorResponse);
        /* yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse })
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error Load List: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
        yield put(isLoadingAction(false));
    }
    // yield put(isLoadingAction(false));
}
export function* reversal({ payload }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REVERSAL_ALL}/${payload.deal_number}/${payload.month}`,
            null,
            responseToken,
            'PUT'
        );

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* searchCurrent();
    } catch (error) {
        if (error.response) {
            const errorResponse = error.response;
            yield put({ type: SUBDEAL_API_REVERSAL_SEARCH_ERROR, errorResponse });
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        }
        console.log(error);
    }
    yield put(isLoadingAction(false));
}

export default function* subdealApi() {
    yield takeLatest(SUBDEAL_API_START_SEARCH, search);
    yield takeLatest(SUBDEAL_API_EDIT_START, edit);
    yield takeLatest(SUBDEAL_API_DELETE_START, deleteSubDeal);
    yield takeLatest(SUBDEAL_API_REVERSAL_SEARCH_START, reversal);
}
