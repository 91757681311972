import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'rgba(255,255,255,0.5)',
    },
}));
export default (props) => {
    const { isLoadingResponse, size } = props;
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={isLoadingResponse}>
            <CircularProgress size={size} />
        </Backdrop>
    );
};
