import React from 'react';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';

import { DialogMessage, SimpleProgress } from '../../components';
import { CardTerm } from './components';

const View = (props) => {
    const {
        TMFilterData,
        showTMListHandler,
        isLoading,
        tmMembersList,
        addTMMemberData,
        submitMemberToTMHandler,
        deleteMemberOfTMHandler,
    } = props;
    const classes = useStyles();

    return (
        <div>
            <DialogMessage />
            <div className={classes.root}>
                <Typography variant='h3' gutterBottom>
                    Team Manager
                </Typography>

                <Card>
                    <CardContent>
                        <CardTerm
                            data={TMFilterData}
                            onSubmit={showTMListHandler}
                            isLoading={isLoading}
                        />
                    </CardContent>

                    <CardContent>
                        <Typography variant='h3'>Add member</Typography>
                        <CardTerm
                            data={addTMMemberData}
                            onSubmit={submitMemberToTMHandler}
                            isLoading={isLoading}
                            addButton={true}
                        />
                    </CardContent>
                </Card>
            </div>
            <div className={classes.root}>
                <Typography variant='h3' gutterBottom>
                    Team Manager members
                </Typography>
                {isLoading ? (
                    <SimpleProgress />
                ) : (
                    <div>
                        <Card>
                            <CardContent>
                                <List component='div' aria-label='secondary mailbox folders'>
                                    {React.Children.toArray(
                                        tmMembersList?.map((tm) => (
                                            <>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={tm.dms_number + ' ─ ' + tm.name}
                                                    />
                                                    <ListItemIcon
                                                        classes={{ root: classes.cursor }}
                                                        onClick={deleteMemberOfTMHandler.bind(
                                                            null,
                                                            tm.dms_number
                                                        )}
                                                    >
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))
                                    )}

                                    {!tmMembersList?.length && (
                                        <ListItem className={classes.center}>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.primary,
                                                }}
                                                primary='NOT_FOUND'
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    );
};

View.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    tmMembersList: PropTypes.array.isRequired,
    addTMMemberData: PropTypes.object.isRequired,
    submitMemberToTMHandler: PropTypes.func.isRequired,
    deleteMemberOfTMHandler: PropTypes.func.isRequired,
};

export default View;
