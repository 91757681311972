import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Index = (props) => {
    const { data, infoSearch } = props;
    return (
        <div>
            <ReactHTMLTableToExcel
                id='test-table-xls-button'
                className='download-table-xls-button'
                table='table-to-xls'
                filename={`SalesForMounth_${infoSearch.month}${infoSearch.year}`}
                sheet='SalesForMounth'
                buttonText='Download as XLS'
            />
            <Box display='none'>
                <table id='table-to-xls'>
                    <thead>
                        <tr>
                            <th>DEAL</th>
                            <th>SANO1</th>
                            <th>SANO2</th>
                            <th>COSTUMER</th>
                            <th>STATUS</th>
                            <th>NEW</th>
                            <th>USED</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.FIWI}</td>
                                <td>{row.SANO1}</td>
                                <td>{row.SANO2}</td>
                                <td>{row.customer}</td>
                                <td>{row.ST}</td>
                                <td>{row.new}</td>
                                <td>{row.used}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </div>
    );
};
Index.propTypes = {
    data: PropTypes.array.isRequired,
    infoSearch: PropTypes.object.isRequired,
};
Index.defaultProps = {};

export default Index;
