import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import { CardDetail, CardTableMultiRow } from './CardDetail';
import { useSelector } from 'react-redux';
import { listResponseSelector } from '../../../../redux/selectors';

const View = (props) => {
    const { bonus, summary, payroll, typeAssociate, dms, dmsData, month, year } = props;
    let newPayroll = { ...payroll };

    const [associate, setAssociate] = useState({});
    const listTerm = useSelector((state) => listResponseSelector(state, 'termReducer'));

    const fields = [
        'Subtotal Commission',
        'Extra Bonus',
        'Subtotal Bonus For Units Sold',
        'Unit Volume Bonus',
        'Subtotal',
        'Adjustment',
        'Special Adjustment',
        'Subtotal Plus Adjustmet',
        'Draw',
        'TOTAL',
    ];

    const uniquePayroll = {};

    delete newPayroll.New;
    delete newPayroll.Used;

    fields.forEach((key) => {
        if (newPayroll.hasOwnProperty(key)) uniquePayroll[key] = newPayroll[key];
        delete newPayroll[key];
    });

    let newSummary = { ...summary };

    delete summary['Commission used car'];
    delete summary['Commission new car'];

    useEffect(() => {
        let payPlanInTerm = {};
        const searchIndex = listTerm.findIndex(
            (term) => term.MONTH === month && term.YEAR === year
        );

        if (searchIndex > -1) {
            payPlanInTerm = listTerm[searchIndex].ASSOCIATES.find((obj) => obj.associate == dms);
        }
        setAssociate(payPlanInTerm);
    }, [listTerm, dms]);

    if (typeAssociate.typeAssociate === '0') {
        newPayroll = { Payplan: associate?.payplan, ...newPayroll };
    }
    console.log(typeAssociate, associate, dms);

    return (
        <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                <CardTableMultiRow title={'Summary'} object={newPayroll} />
                <div style={{ height: 15 }} />
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CardTableMultiRow title={'Bonus'} object={summary} />
                </Grid>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                <CardTableMultiRow title={'Payroll'} object={uniquePayroll} />
            </Grid>
        </Grid>
    );
};

View.Props = {
    bonus: PropTypes.object.isRequired,
    summary: PropTypes.array.isRequired,
    payroll: PropTypes.array.isRequired,
};
export default View;
