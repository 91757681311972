import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, TextField, CardHeader, IconButton } from '@material-ui/core';
import { DeleteOutline, Check } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SelectDropDown } from '../../../../components';

const Index = (props) => {
    const {
        obj,
        isDisable,
        posicionArray,
        handleChange,
        listPayPlan,
        arrayAllAssociate,
        onDeleteAssociateCard,
        add,
        newObjInArrayAssociate,
        onClean,
    } = props;

    const [newAssoPayPlan, setNewAssoPayPlan] = useState({
        associate: '',
        payplan: '',
    });
    const [IsDisableCheck, setIsDisableCheck] = useState(true);
    const [clenAutoComplete, setClenAutoComplete] = useState(true);

    useEffect(() => {
        if (newAssoPayPlan.associate.length > 0 && newAssoPayPlan.payplan.length > 0) {
            setIsDisableCheck(false);
        } else {
            setIsDisableCheck(true);
        }
    }, [newAssoPayPlan]);

    useEffect(() => {
        onCleanCard();
    }, [onClean]);

    const getEvent = (e) => {
        handleChange(posicionArray, e);
    };

    const value = (code) => {
        if (code.length > 0) {
            let arrayBase = arrayAllAssociate;
            let indexArray = arrayBase.findIndex((e) => {
                return e.code === code;
            });
            return arrayBase[indexArray];
        } else {
            return null;
        }
    };
    const getNewAssociate = (v) => {
        let e = {
            target: {
                name: 'associate',
                value: v ? v.code : value(obj.associate).code,
            },
        };
        handleChange(posicionArray, e);
    };
    const onAdd = () => {
        newObjInArrayAssociate(newAssoPayPlan);
        onCleanCard();
    };
    const onCleanCard = () => {
        setNewAssoPayPlan({
            associate: '',
            payplan: '',
        });
        setClenAutoComplete(!clenAutoComplete);
    };
    const onChangeAdd = (value) => {
        if (value) {
            setNewAssoPayPlan({ ...newAssoPayPlan, associate: value.code });
        } else {
            setNewAssoPayPlan({ ...newAssoPayPlan, associate: '' });
        }
    };

    return (
        <Card style={{ padding: 15 }}>
            <CardHeader
                action={
                    <IconButton
                        disabled={add ? IsDisableCheck : isDisable}
                        onClick={() => {
                            add ? onAdd() : onDeleteAssociateCard(obj.associate);
                        }}
                    >
                        {add ? (
                            <Check style={{ fontSize: 18 }} />
                        ) : (
                            <DeleteOutline style={{ fontSize: 18 }} />
                        )}
                    </IconButton>
                }
                subheader={add ? 'New ' : 'Pay plan for Associate'}
            />

            <Autocomplete
                id='combo-box-demo-associate-term'
                size='small'
                style={{ marginBottom: 15 }}
                disabled={isDisable}
                key={clenAutoComplete}
                value={add ? value(newAssoPayPlan.associate) : value(obj.associate)}
                options={arrayAllAssociate}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                    add ? onChangeAdd(value) : getNewAssociate(value);
                }}
                renderInput={(params) => (
                    <TextField {...params} label='associate' variant='outlined' />
                )}
            />
            <SelectDropDown
                labelName={'Pay Plan'}
                nameSelect={'payplan'}
                valueProp={add ? newAssoPayPlan.payplan : obj.payplan}
                onChangeProp={
                    add
                        ? (e) => {
                              setNewAssoPayPlan({ ...newAssoPayPlan, payplan: e.target.value });
                          }
                        : getEvent
                }
                objectList={listPayPlan}
                keyValue={'NAME'}
                keyLabel={'NAME'}
                disableValue={isDisable}
            />
        </Card>
    );
};

Index.propTypes = {
    isDisable: PropTypes.bool.isRequired,
    listPayPlan: PropTypes.array.isRequired,
    arrayAllAssociate: PropTypes.array.isRequired,
    obj: PropTypes.object,
    posicionArray: PropTypes.number,
    handleChange: PropTypes.func,
    onDeleteAssociateCard: PropTypes.func,
    add: PropTypes.bool,
    newObjInArrayAssociate: PropTypes.func,
    onClean: PropTypes.bool.isRequired,
};
Index.defaultProps = {
    add: false,
    obj: {},
    posicionArray: 0,
    handleChange: () => {
        console.log('handleChange');
    },
    onDeleteAssociateCard: () => {
        console.log('onDeleteAssociateCard');
    },
    newObjInArrayAssociate: () => {
        console.log('newObjInArrayAssociate');
    },
};

export default Index;
