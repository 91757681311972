import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        color: '#263238',
    },
    containerTabs: {
        marginTop: theme.spacing(2),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: 'white',
    },
    grid: {
        padding: theme.spacing(2),
    },
    textField: {
        paddingBottom: 10,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 3000,
    },
    paper: { marginTop: theme.spacing(2) },
}));
