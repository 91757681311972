import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flex: 1,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    rightBotton: {
        marginLeft: 'auto',
    },
    leftButton: {
        marginRight: 'auto',
    },
    maringTop10: {
        marginTop: 10,
    },
}));
