import React from 'react';
import View from './view';
import { useHistory } from 'react-router-dom';
export default () => {
    const history = useHistory();
    const goToBack = () => {
        history.push('/commission_pages');
    };
    return <View goToBack={goToBack} />;
};
