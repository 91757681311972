import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 64,
        height: '100%',
    },
    content: {
        height: '100%',
    },
}));
