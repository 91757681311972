import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const AutoComplete = ({ listOptions, disabled, onChange, label }) => (
    <Autocomplete
        id={'combo-box-' + label.split(' ').join('-')}
        options={listOptions}
        size="small"
        disabled={disabled}
        getOptionLabel={(option) => option?.name || option?.NAME}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
);

AutoComplete.propTypes = {
    handleClick: PropTypes.func,
    listOptions: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};
