import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
    Associates as AssociatesView,
    SignIn as SignInView,
    Adjustment as AdjustmentView,
    AdjustmentV2 as AdjustmentV2,
    WashoutSheets as WashoutSheetsView,
    WashoutSheetsV2 as WashoutSheetsV2,
    ListDealAssByMonth as ListDealAssByMonth,
    SimpleSearchCard as SimpleSearchCardView,
    SimpleSearchCardV2 as SimpleSearchCardV2,
    PayPlan as PayPlanView,
    PayPlanV2 as PayPlanV2,
    Term as TermView,
    TermV2 as TermV2,
    InConstruction as InConstructionView,
    TeamManager as TeamManagerView,
    TransferDeal as TransferDeal,
} from './views';

export default () => {
    return (
        <Switch>
            <Route exact path='/'>
                <SignInView />
            </Route>
            <Route exact path='/not-found'>
                <InConstructionView />
            </Route>
            <RouteWithLayout
                component={AssociatesView}
                exact
                layout={MainLayout}
                path='/associates'
            />
            <RouteWithLayout
                component={WashoutSheetsV2}
                exact
                layout={MainLayout}
                path='/commission_pages'
            />
            {/* <RouteWithLayout
                component={AdjustmentView}
                exact
                layout={MainLayout}
                path='/adjustment'
            /> */}
            <RouteWithLayout
                component={AdjustmentV2}
                exact
                layout={MainLayout}
                path='/adjustment'
            />
            <RouteWithLayout
                component={TransferDeal}
                exact
                layout={MainLayout}
                path='/transfer_deal'
            />

            {/* <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/search_deals'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/search_subdeals'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/search_deals_month'
                reducerPath='searchReducer'
            /> */}
            <RouteWithLayout
                component={SimpleSearchCardV2}
                exact
                layout={MainLayout}
                path='/search_deals_month'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardV2}
                exact
                layout={MainLayout}
                path='/search_deals'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardV2}
                exact
                layout={MainLayout}
                path='/search_by_deals'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardV2}
                exact
                layout={MainLayout}
                path='/search_subdeals'
                reducerPath='searchReducer'
            />
            <RouteWithLayout
                component={ListDealAssByMonth}
                exact
                layout={MainLayout}
                path='/list_deals_ass_month'
                reducerPath='searchReducer'
            />

            {/* <RouteWithLayout component={PayPlanView} exact layout={MainLayout} path='/payplans' /> */}
            <RouteWithLayout component={PayPlanV2} exact layout={MainLayout} path='/payplans' />
            {/* <RouteWithLayout component={TermView} exact layout={MainLayout} path='/term' /> */}
            <RouteWithLayout component={TermV2} exact layout={MainLayout} path='/term' />
            <RouteWithLayout
                component={TeamManagerView}
                exact
                layout={MainLayout}
                path='/team_manager'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/all_washout'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_commission_month'
                reducerPath='reportReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_sales'
                reducerPath='reportReducer'
            />

            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_payout'
                reducerPath='reportReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_payout_per_store'
                reducerPath='reportReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_tms'
                reducerPath='reportReducer'
            />
            <RouteWithLayout
                component={SimpleSearchCardView}
                exact
                layout={MainLayout}
                path='/reports_tms_sales'
                reducerPath='reportReducer'
            />

            <Redirect to='/not-found' />
        </Switch>
    );
};
