import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { isLoading, dinamicTableSelector } from '../../redux/selectors';

import View from './view';

const Index = (props) => {
    const classes = useStyles();
    const {
        reducer,
        object,
        editable,
        onAdd,
        onDelete,
        hideRowDelete = false,
        onEdit,
        ...rest
    } = props;

    const isLoadingResponse = useSelector((state) => isLoading(state));
    const dataTable = useSelector((state) => dinamicTableSelector(state, reducer, object));
    const [loading, setLoading] = useState(false);

    const initalRowNum = () => {
        let numRow = dataTable.data.length;
        if (numRow <= 30) {
            return numRow;
        }
        if (numRow >= 30) {
            return 30;
        }
        return 1;
    };
    const optionRowNum = () => {
        let numRow = dataTable.data.length;
        if (numRow <= 30) {
            return [numRow];
        }
        if (numRow > 30) {
            return [30, 90, numRow];
        }
        return [1];
    };
    const rowEditables = {
        onRowAdd: (newData) =>
            new Promise((resolve) => {
                resolve();
                onAdd(object, newData, dataTable.data[dataTable.data.length - 1]);
            }),
        onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
                resolve();
                onEdit(object, newData, oldData);
            }),
        onRowDelete: hideRowDelete
            ? false
            : (oldData) =>
                  new Promise((resolve) => {
                      resolve();
                      onDelete(object, oldData);
                  }),
    };

    return (
        <View
            state={dataTable}
            isLoadingResponse={isLoadingResponse}
            initalRowNum={initalRowNum}
            optionRowNum={optionRowNum}
            object={object}
            rowEditables={editable ? rowEditables : null}
            actionActive={editable}
            loading={loading}
            {...rest}
        />
    );
};

Index.propTypes = {
    reducer: PropTypes.string.isRequired,
    object: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    hideRowDelete: PropTypes.bool,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default Index;
