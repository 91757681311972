import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import './styles.css';
import { Typography } from '@material-ui/core';
import { TablePrint, TableSimplePrint } from './table';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { listResponseSelector } from '../../redux/selectors';

export default (props) => {
    const {
        dataTableAdjustment,
        bonus,
        payroll,
        dmsData,
        summary,
        dataTableDeals,
        dataTableDeals2,
        dataTableDealsNew,
        dataTableDealsUsed,
        dateReport,
        isPrintLessDetails,
        isTM,
        dataSearchDeals,
    } = props;
    const classes = useStyles();
    const lengthTable = {
        dataTableDeals: dataTableDeals.data.length,
        dataTableDeals2: dataTableDeals2 ? dataTableDeals2.data.length : 0,
        dataTableDealsNew: dataTableDealsNew ? dataTableDealsNew.data.length : 0,
        dataTableDealsUsed: dataTableDealsUsed ? dataTableDealsUsed.data.length : 0,
    };

    const [associate, setAssociate] = useState({});
    const listTerm = useSelector((state) => listResponseSelector(state, 'termReducer'));

    let newPayroll = { ...payroll };

    useEffect(() => {
        let payPlanInTerm = {};
        const searchIndex = listTerm.findIndex(
            (term) => term.MONTH === dataSearchDeals?.month && term.YEAR === dataSearchDeals?.year
        );

        if (searchIndex > -1) {
            payPlanInTerm = listTerm[searchIndex].ASSOCIATES.find(
                (obj) => obj.associate == dataSearchDeals?.dms
            );
        }
        setAssociate(payPlanInTerm);
    }, [listTerm, dataSearchDeals]);

    if (dmsData.type === 'Seller') {
        newPayroll = { Payplan: associate?.payplan, ...newPayroll };
    }

    const fields = [
        'Subtotal Commission',
        'Subtotal Bonus For Units Sold',
        'Extra Bonus',
        'Unit Volume Bonus',
        'Subtotal',
        'Adjustment',
        'Special Adjustment',
        'Subtotal Plus Adjustment',
        'Subtotal Plus Adjustmet',
        'Draw',
        'TOTAL',
    ];

    const uniquePayroll = {};

    fields.forEach((key) => {
        if (newPayroll.hasOwnProperty(key)) uniquePayroll[key] = newPayroll[key];
        delete newPayroll[key];
    });

    let newSummary = { ...summary };
    if (isPrintLessDetails) {
        delete newPayroll.New;
        delete newPayroll.Used;
        delete newSummary['Commission used car'];
        delete newSummary['Commission new car'];
    } else {
        if (Object.keys(newSummary).length > 1) {
            delete newSummary['Extra Bonus'];
        }
    }
    if (dmsData.type !== 'FIM') {
        delete newSummary['Extra Bonus'];
    }
    /*** catnidad maxima de filas hoja1 = 27*/

    const date = () => {
        let dateNow = new Date();
        let month = dateNow.getMonth() + 1;
        let day = dateNow.getDate();
        let year = dateNow.getFullYear();
        const format = (n) => {
            return (n < 10 ? '0' : '') + n;
        };
        let h = dateNow.getHours();
        let m = dateNow.getMinutes();
        let s = dateNow.getSeconds();

        return (
            year +
            '/' +
            format(month) +
            '/' +
            format(day) +
            '--' +
            format(h) +
            ':' +
            format(m) +
            ':' +
            format(s)
        );
    };
    return (
        <div className='container-table'>
            <table className='report-container'>
                <thead className='report-header'>
                    <tr>
                        <th className='report-header-cell'>
                            <div className='header-info'>
                                <div className='pageHeader' align='center'>
                                    <img height='50px' src='/images/logos/logoUpturnPrint.png' />
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tfoot className='report-footer'>
                    <tr>
                        <td className='report-footer-cell'>
                            <div className='footer-info'></div>
                        </td>
                    </tr>
                </tfoot>
                <tbody className='report-content'>
                    <tr>
                        <td className='report-content-cell'>
                            <div className='main'>
                                <div className='page-content-inside-auto container-table-1'>
                                    <Typography variant='h4'>
                                        {`${dateReport} - ${dmsData.code} - ${dmsData.type} - ${dmsData.name}`}
                                    </Typography>
                                    <Typography variant='subtitle2' className={classes.subTitle}>
                                        {date()}
                                    </Typography>
                                    {dataTableDeals ? (
                                        <TablePrint dataTable={dataTableDeals} />
                                    ) : null}
                                    {/*dataTableDeals2 ? <TablePrint dataTable={dataTableDeals2}/> : null*/}
                                </div>

                                {!isTM}

                                {!isPrintLessDetails && dataTableDealsNew ? (
                                    <div className='page-content-inside-auto container-table-2'>
                                        <Typography
                                            variant='subtitle2'
                                            className={classes.subTitle}
                                        >
                                            New Car
                                        </Typography>
                                        <TablePrint
                                            dataTable={dataTableDealsNew}
                                            length={lengthTable.dataTableDealsNew}
                                        />
                                    </div>
                                ) : null}

                                {!isPrintLessDetails && dataTableDealsUsed ? (
                                    <div className='page-content-inside-auto container-table-3'>
                                        <Typography
                                            variant='subtitle2'
                                            className={classes.subTitle}
                                        >
                                            Used Car
                                        </Typography>
                                        <TablePrint
                                            dataTable={dataTableDealsUsed}
                                            length={lengthTable.dataTableDealsUsed}
                                        />
                                    </div>
                                ) : null}

                                <div className='page-content-inside-always container-table-4'>
                                    <div className='columm-1'>
                                        <Typography
                                            variant='subtitle2'
                                            className={classes.subTitle}
                                        >
                                            Adjustment
                                        </Typography>
                                        <TablePrint dataTable={dataTableAdjustment} />
                                    </div>
                                    {!isTM && (
                                        <div className='columm-2'>
                                            <Typography
                                                variant='subtitle2'
                                                className={classes.subTitle}
                                            >
                                                Bonus
                                            </Typography>
                                            <TableSimplePrint
                                                dataTable={newSummary}
                                                name={'payroll'}
                                            />
                                        </div>
                                    )}

                                    <div className='columm-3'>
                                        <Typography
                                            variant='subtitle2'
                                            className={classes.subTitle}
                                        >
                                            Summary
                                        </Typography>
                                        <TableSimplePrint dataTable={newPayroll} name={'summary'} />
                                    </div>
                                    <div className='columm-4'>
                                        <Typography
                                            variant='subtitle2'
                                            className={classes.subTitle}
                                        >
                                            Payroll
                                        </Typography>

                                        <div
                                            className='table-bonus'
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <TableSimplePrint
                                                dmsCode={dmsData.code}
                                                dataTable={uniquePayroll}
                                                name={'payroll'}
                                            />
                                        </div>
                                        <div className='table-bonus'>
                                            <Typography className='td-bonus-new'>
                                                {'Christmas bonus'}{' '}
                                            </Typography>
                                            <Typography className='td-bonus'>
                                                {' '}
                                                {`$${bonus?.toFixed(2)}`}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
