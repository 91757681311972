export const URL_API = 'https://apikia.hdupturn.com/';
export const AUTH = 'auth';
export const ASSOCIATE_LIST = 'associate_list';
export const ASSOCIATE_LIST_DROP_DOWN = 'associate_list_drop_down';
export const ASSOCIATE_LIST_BY_TYPE = 'associate_list_by_type/';
export const ASSOCIATE_LIST_BY_TYPE_AND_STORE = 'associate_list_by_type_and_store/';
export const ASSOCIATE = 'associate/';
export const WASHOUT_ASSOC_MONTH = 'WASHOUT_assoc_month';
export const ADJUSTMENT = 'adjustment';
export const ADJUSTMENT_DATA = 'adjustments_data';
export const WASHOUT = 'washout';
export const WASHOUT_TM = 'washout_tm';
export const PAYPLAN_SENIOR_PRODUCT_SPECIALIST = 'payplan_senior_product_specialist';
export const PAYPLAN_BUSINESS_MENAGER = 'payplan_business_manager';
export const PAYPLAN_PRODUCT_SPECIALIST = 'payplan_senior_product_specialist';
export const DATA = 'data';
export const SUBDEALS = 'subdeals';
export const SUBDEAL_API = 'subdeal_api';
export const DEAL = 'deal';
export const SUBDEAL_ASSOC_DEAL = 'subdeal_assoc_deal';
export const DEALS = 'deals';
export const DEAL_API = 'deal_api';
export const PAYPLAN = 'payplan';
export const TERM = 'term';
export const TERM_PAYPLAN = 'term_payplan';
export const MONTH = 'month';
export const ALLWASHOUTS = 'allwashouts';
export const REVERSAL = 'reversal';
export const REVERSAL_ALL = 'reversal_all';
export const UNIT_ADJUSTMENT = 'unit_adjustment';
export const REPORT_TOTALS_1 = 'report_totals_1';
export const REPORT_SALES_1 = 'report_sales_1';
export const REPORT_PAYOUT_1 = 'report_payout_1';
export const REPORT_PAYOUT_PER_STORE = 'report_payout_per_store';
export const REPORT_TOTALS_TM = 'report_totals_tm';
export const TM = 'team_manager';
export const TEAM_MANAGER_DRAW = 'team_manager_draw';
export const REPORT_TOTALS_ALL_PLUS_TM = 'report_totals_all_plus_tm';
export const TRANSFER_DEAL = 'deals_to_transfer/';
export const SP = 'associate_sdt_list_by_type/'
export const FIM = 'associate_sdt_list_by_type/'
export const UPDATE_TRANSFER_DEAL = 'deals_to_transfer'

/*** live  http://kia.hdupturn.com/sign-in
 *
 *
 */
