import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    divPrint: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingRight: 25,
        paddingLeft: 25,
        height: '100%',
    },

    field: {
        display: 'flex',
        flexDirection: 'row',
    },
    table: {},
    cell: {
        fontSize: 10,
    },
    titleButton: {
        display: 'flex',
        flexDirection: 'row',
    },
    iconButton: {
        marginLeft: 15,
        fontSize: 5,
    },
    textField: {
        marginTop: 10,
    },
}));
