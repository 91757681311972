import { get } from 'lodash';

import { GET_TM_COMPLETE } from '../../consts/actionTypes';

const initial_state = {
    listResponse: [],
};

export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_TM_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'data'),
            };
        default:
            return state;
    }
}
