import { put, call, takeLatest, select, delay } from 'redux-saga/effects';
import { apiCall } from '../api';
import { EXTRACTION_START } from '../../consts/actionTypes';
import { snackBarAction } from '../actions/utilitiesAction';
import { token } from '../selectors';
import { SUBDEALS, DATA } from '../../consts/urls';

export function* extractionStart({ data, endPoint }) {
    try {
        const responseToken = yield select(token);
        let results = null;
        if (endPoint === SUBDEALS) {
            results = yield call(apiCall, `${SUBDEALS}/${data.term}`, null, responseToken, 'GET');
        } else {
            results = yield call(apiCall, endPoint, data, responseToken, 'POST');
        }

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        /* if (endPoint === DATA) {
            yield delay(20000);
            const results2 = yield call(apiCall, `${SUBDEALS}/${data.term}`, null, responseToken, 'GET');

            yield put(snackBarAction(
                {
                    open: true,
                    msg: results2.data.message,
                    type: 'success'
                }
            ));
        }*/
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error extraction: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
}

export default function* extraction() {
    yield takeLatest(EXTRACTION_START, extractionStart);
}
