import React, { useMemo, useRef } from 'react';
import { useStyles } from './styles';
import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Divider,
    TextField,
    RadioGroup,
    Radio,
    IconButton,
    Grid,
    Typography,
    Fab,
} from '@material-ui/core';
import { SelectDropDown, SimpleProgress } from '../../components';
import { months, years } from '../../consts/date';
import SearchIcon from '@material-ui/icons/Search';
import {
    SearchDealByNumber,
    SearchDealByMouth,
    AllWashout,
    ReportTotals,
    ReportSales,
    ReportTMs,
} from './components';
import ReactToPrint from 'react-to-print';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { ReportsActions } from '../../components/ReportActions';

const SubDealsCard = (props) => {
    const { arrayData } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            {arrayData.map((object, index) => {
                return (
                    <Grid key={index} item xl={2} lg={3} md={4} sm={6} xs={12}>
                        <Card className={classes.grid}>
                            {Object.keys(object).map((keyObject, indexOb) => {
                                if (keyObject === '_id') {
                                    return;
                                }
                                return (
                                    <div className={classes.grid} key={indexOb}>
                                        <Typography color='secondary' variant='h4'>
                                            {keyObject}
                                        </Typography>
                                        <Typography>{object[keyObject]}</Typography>
                                    </div>
                                );
                            })}
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default (props) => {
    const {
        cardConf,
        onSearch,
        onChangeInput,
        dataSearch,
        listResponse,
        isLoadingResponse,
        listOrderDataState,
        allWashoutState,
        storeValue,
        storeHandleChange
    } = props;
    const classes = useStyles();
    const refDivPrintDeal = useRef();
    const [typeValue, setTypeValue] = React.useState('ALL');

    const typeHandleChange = (event) => {
        setTypeValue(event.target.value);
    };

    const ActionCard = useMemo(() => {
        switch (cardConf.menu) {
            case 'a':
                return (
                    <div>
                        <TextField
                            placeholder={'Deal'}
                            className={classes.fieldSearchA}
                            name={'deal'}
                            onChange={onChangeInput}
                            value={dataSearch.deal}
                        />
                    </div>
                );
            case 'b':
                return (
                    <div>
                        <TextField
                            placeholder={'Associate Num'}
                            className={classes.fieldSearchB}
                            name={'associate_num'}
                            onChange={onChangeInput}
                            value={dataSearch.associate_num}
                        />
                        <TextField
                            placeholder={'Deal'}
                            className={classes.fieldSearchB}
                            name={'deal'}
                            onChange={onChangeInput}
                            value={dataSearch.deal}
                        />
                    </div>
                );
            case 'c':
            case 'd':
            case 'e':
            case 'f':
                return (
                    <ReportsActions
                        dataSearch={dataSearch}
                        onChangeInput={onChangeInput}
                        classes={classes}
                        typeValue={typeValue}
                        typeHandleChange={typeHandleChange}
                        radioOptions={['All', 'FIM', 'Seller', 'TM']}
                    />
                );

            case 'g':
                return (
                    <ReportsActions
                        dataSearch={dataSearch}
                        onChangeInput={onChangeInput}
                        classes={classes}
                        typeValue={typeValue}
                        typeHandleChange={typeHandleChange}
                        radioOptions={['FIM', 'Seller']}
                    />
                );
            case 'h':
                return (
                    <ReportsActions
                        dataSearch={dataSearch}
                        onChangeInput={onChangeInput}
                        classes={classes}
                        isDisableType={true}
                    />
                );
            case 'i':
                return (
                    <ReportsActions
                        dataSearch={dataSearch}
                        onChangeInput={onChangeInput}
                        classes={classes}
                        isDisableType={true}
                        case={'i'}
                    />
                );
            case 'j':
                return (
                    <ReportsActions
                        dataSearch={dataSearch}
                        onChangeInput={onChangeInput}
                        classes={classes}
                        typeValue={typeValue}
                        typeHandleChange={typeHandleChange}
                        radioOptions={['FIM', 'Seller']}
                        enableFilterOption={true}
                        filterValue={storeValue}
                        filterHandleChange={storeHandleChange}
                        filterOptions={['All', 'Kia', 'Homestead']}
                        
                    />
                );
            default:
                return <div />;
        }
    });
    const ViewList = useMemo(() => {
        if (isLoadingResponse) {
            return (
                <Grid
                    container
                    spacing={0}
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={{ minHeight: '60%' }}
                >
                    <SimpleProgress />
                </Grid>
            );
        }
        if (!allWashoutState) {
            if (!listResponse) {
                return <div />;
            }
        }
        switch (cardConf.menu) {
            case 'a':
                return (
                    <div>
                        <ReactToPrint
                            trigger={() => (
                                <Fab className={classes.fabPrint}>
                                    <PrintOutlinedIcon fontSize='large' />
                                </Fab>
                            )}
                            content={() => refDivPrintDeal.current}
                        />
                        <div ref={refDivPrintDeal}>
                            <SearchDealByNumber deal={dataSearch} />
                        </div>
                    </div>
                );
            case 'b':
                let basicData = listResponse.basic;
                let backData = listResponse.back;
                let frontData = listResponse.front;
                return (
                    <div>
                        <Grid container spacing={2}>
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === '_id' || keyObject === 'payment_data') {
                                    return;
                                }
                                return (
                                    <Grid key={index} item xl={1} lg={2} md={3} sm={4} xs={6}>
                                        <div className={classes.grid}>
                                            <Typography color='secondary' variant='h4'>
                                                {keyObject}
                                            </Typography>
                                            <Typography>{basicData[keyObject]}</Typography>
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Card>
                            <CardHeader title='Front' />
                            <CardContent>
                                <SubDealsCard arrayData={frontData} />
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title='Back' />
                            <CardContent>
                                <SubDealsCard arrayData={backData} />
                            </CardContent>
                        </Card>
                    </div>
                );
            case 'c':
                return <SearchDealByMouth orderData={listOrderDataState} />;
            case 'd':
                return (
                    <AllWashout
                        allWashout={allWashoutState}
                        dateSearch={`${dataSearch.month}${dataSearch.year}`}
                    />
                );
            case 'e':
                return <ReportTotals data={listResponse} />;
            case 'f':
                return <ReportSales data={listResponse} infoSearch={dataSearch} />;
            case 'g':
                return (
                    <ReportTotals tbType='payout' hideColumnInFIM={typeValue} data={listResponse} />
                );
            case 'h':
                return <ReportTMs infoSearch={dataSearch} data={listResponse} />;
            case 'i':
                return <ReportTMs infoSearch={dataSearch} data={listResponse} />;
            case 'j':
                return <ReportTotals tbType='payoutPerStore' infoSearch={dataSearch} data={listResponse} hideColumnInFIM={typeValue} />;
            default:
                return <div />;
        }
    });
    return (
        <div className={classes.root}>
            <Card>
                <CardHeader
                    title={cardConf.title}
                    action={
                        <div className={classes.actionHeader}>
                            {ActionCard}
                            <IconButton
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                    onSearch(typeValue);
                                }}
                            >
                                <SearchIcon fontSize='small' />
                            </IconButton>
                        </div>
                    }
                />
                <Divider />
                {cardConf.title === 'Report Total TMs' ||
                cardConf.title === 'Report Total TMs Sales' ? (
                    <CardContent className={classes.cardContentTMs}>{ViewList}</CardContent>
                ) : (
                    <CardContent className={classes.cardContent}>{ViewList}</CardContent>
                )}
            </Card>
        </div>
    );
};
/** */
