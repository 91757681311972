import React from 'react';
import { useStyles } from './styles';

import { SimpleProgress, DinamicTable } from '../../components';

export default (props) => {
    const { isLoadingResponse, onRowAdd, onRowDelete, onRowEdit } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {isLoadingResponse ? (
                <SimpleProgress />
            ) : (
                <div style={{ maxWidth: 1420 }}>
                    <DinamicTable
                        reducer={'payPlanReducerV2'}
                        tableTitle={'Payplan'}
                        object={'dinamicTableConf'}
                        isGrouping={true}
                        onAdd={onRowAdd}
                        onDelete={onRowDelete}
                        onEdit={onRowEdit}
                        editable={true}
                        hideRowDelete={true}
                    />
                </div>
            )}
        </div>
    );
};
