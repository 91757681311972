import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import View from './view';
import { snackBarAction, dialogMessageAction } from '../../../../redux/actions/utilitiesAction';
import { deleteTermAction } from '../../../../redux/actions/termAction';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';

const Index = (props) => {
    const { terms, associate, onEdit, add, onAdd, ...rest } = props;
    const dispatch = useDispatch();
    const [autoPayPlan, setAutoPayPlan] = useState({
        payplan: '',
        associate: '',
        index: 0,
    });
    const [expanded, setExpanded] = useState(add);
    const [handleClick, setHandleClick] = useState({
        cleanCardTerm: false,
        edit: add,
        key: false,
    });

    const [data, setData] = useState({ ...terms });

    const object = {};
    const handleOnChangeAutoComplete = (obj) => {
        let array = [...data.ASSOCIATES];
        if (obj) {
            let indexArray = array.findIndex((e) => {
                return e.associate === obj.associate;
            });
            setAutoPayPlan({ ...autoPayPlan, index: indexArray, ...obj });
        } else {
            setAutoPayPlan({
                payplan: '',
                associate: '',
                index: 0,
            });
        }
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handlePositiveClick = () => {
        if (handleClick.edit) {
            if (add) {
                validateFields('add');
            } else {
                validateFields('edit');
            }
        } else {
            if (add) {
                //setHandleClick({ add: false, edit: true })
            } else {
                setHandleClick({ ...handleClick, edit: true });
            }
        }
    };
    const handleNegativeClick = (action) => {
        if (handleClick.edit) {
            if (add) {
                setData({
                    ...terms,
                });
                setHandleClick({
                    ...handleClick,
                    cleanCardTerm: !handleClick.cleanCardTerm,
                });
            } else {
                setData({
                    ...terms,
                });
                setHandleClick({
                    ...handleClick,
                    edit: false,
                    key: !handleClick.key,
                    cleanCardTerm: !handleClick.cleanCardTerm,
                });
            }
        } else {
            openConfirmDialog(action, true);
        }
    };
    const onChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const onChangeDataArray = (i, e) => {
        let associateObject = { ...data.ASSOCIATES[i] };
        let associateArray = [...data.ASSOCIATES];
        let datatemp = { ...data };
        associateObject = {
            ...associateObject,
            [e.target.name]: e.target.value,
        };
        associateArray[i] = { ...associateObject };
        datatemp = { ...data, ASSOCIATES: [...associateArray] };

        setData({
            ...datatemp,
        });
    };

    const onDelete = () => {
        openConfirmDialog(null, false);
        dispatch(isLoadingAction(true));
        dispatch(deleteTermAction(terms._id));
    };
    const openConfirmDialog = (action, openDialog) => {
        dispatch(
            dialogMessageAction({
                open: openDialog,
                title: 'comfirmation',
                text: 'Are you sure you want to delete this term?',
                children: action,
            })
        );
    };
    const getEvent = (e) => {
        onChangeDataArray(autoPayPlan.index, e);
    };

    const listAssocietewithName = () => {
        let listAssociate = associate;
        let list = [];
        data.ASSOCIATES.map((obj, i) => {
            let indexArray = listAssociate.findIndex((e) => {
                return e.code === obj.associate;
            });
            if (indexArray === -1) {
                return;
            }
            let newobj = { ...obj, name: listAssociate[indexArray].name };
            list.push(newobj);
        });
        return list;
    };
    const onDeleteAssociateCard = (code) => {
        let newArrayAssociate = data.ASSOCIATES.filter((e) => {
            return e.associate !== code;
        });
        setData({ ...data, ASSOCIATES: newArrayAssociate });
    };
    const newObjInArrayAssociate = (newObj) => {
        let newArrayAssociate = [...data.ASSOCIATES, newObj];
        setData({ ...data, ASSOCIATES: newArrayAssociate });
    };
    const validateFields = (func) => {
        for (const key in data) {
            let msgTemp = '';
            if (data[key].length === 0) {
                msgTemp = `the Field ${key} is empty`;
                if (key === 'ASSOCIATES' && func === 'add') continue;

                dispatch(
                    snackBarAction({
                        open: true,
                        msg: msgTemp,
                        type: 'error',
                    })
                );
                return;
            }
        }
        if (func === 'add') {
            onAdd(data);
            setData({
                ...terms,
            });
            setHandleClick({
                ...handleClick,
                cleanCardTerm: !handleClick.cleanCardTerm,
            });
        }
        if (func === 'edit') {
            onEdit(data);
            setHandleClick({ ...handleClick, edit: false, key: !handleClick.key });
        }
    };

    return (
        <View
            handleClick={handleClick}
            listTerm={data}
            onChangeData={onChangeData}
            listAutoComplete={listAssocietewithName}
            handleOnChangeAutoComplete={handleOnChangeAutoComplete}
            autoPayPlan={autoPayPlan}
            getEvent={getEvent}
            handlePositiveClick={handlePositiveClick}
            handleNegativeClick={handleNegativeClick}
            arrayAllAssociate={associate}
            newObjInArrayAssociate={newObjInArrayAssociate}
            onChangeDataArray={onChangeDataArray}
            onDeleteAssociateCard={onDeleteAssociateCard}
            expanded={expanded}
            handleExpandClick={handleExpandClick}
            add={add}
            openConfirmDialog={openConfirmDialog}
            onDelete={onDelete}
            {...rest}
        />
    );
};

Index.propTypes = {
    terms: PropTypes.object,
    add: PropTypes.bool,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    associate: PropTypes.array.isRequired,
};
Index.defaultProps = {
    terms: {
        ASSOCIATES: [],
        INT: '',
        MONTH: '',
        NAME: '',
        YEAR: '',
    },
    onEdit: () => {
        console.log();
    },
    add: false,
    onAdd: () => {
        console.log();
    },
};

export default Index;
