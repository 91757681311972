import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: 'relative',
    },
    item: {
        color: '#019e59',
    },
}));
