import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import View from './view';
import { useDispatch } from 'react-redux';
import { extractionAction } from '../../../../../../redux/actions/extractionAction';

const Index = (props) => {
    const dispatch = useDispatch();
    const { openNested, handleNestedClick } = props;
    const [dataExtraction, setDataExtraction] = useState({
        deal: '',
        month: '',
        year: '',
    });
    const [dialogMessageData, setDialogMessageData] = useState({
        open: false,
        title: '',
        text: '',
        action: '',
        endPoint: '',
    });

    const onClickMessageButton = (obj) => {
        setDialogMessageData({
            open: true,
            title: obj.title,
            text: obj.text,
            action: obj.action,
            endPoint: obj.endPoint,
        });

        // onClose()
    };
    const onChangeDataExtraction = (e) => {
        setDataExtraction({ ...dataExtraction, [e.target.name]: e.target.value });
    };
    const handleCloseDilogMessage = () => {
        setDialogMessageData({ ...dialogMessageData, open: false });
        setDataExtraction({
            deal: '',
            month: '',
            year: '',
        });
    };
    const onClickExtraction = () => {
        let data = {
            extract: true,
        };
        let endPoint = dialogMessageData.endPoint;
        if (dialogMessageData.action === 'term') {
            data = { ...data, term: `${dataExtraction.month}${dataExtraction.year}` };
        }
        if (dialogMessageData.action === 'termTMDraws') {
            data = { ...data, MONTH: `${dataExtraction.month}${dataExtraction.year}` };
        }
        if (dialogMessageData.action === 'deal') {
            data = { ...data, deal: dataExtraction.deal };
        }

        dispatch(extractionAction(data, endPoint));
        handleCloseDilogMessage();
    };

    return (
        <View
            openNested={openNested}
            handleNestedClick={handleNestedClick}
            onClickMessageButton={onClickMessageButton}
            dataExtraction={dataExtraction}
            onChangeDataExtraction={onChangeDataExtraction}
            dialogMessageData={dialogMessageData}
            handleCloseDilogMessage={handleCloseDilogMessage}
            onClickExtraction={onClickExtraction}
        />
    );
};
Index.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Index;
