import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
    Paper,
    Card,
    Grid,
    CardContent,
    CardActions,
    TextField,
    IconButton,
    Collapse,
    Button,
} from '@material-ui/core';
import { DeleteOutline, Edit, Check, Clear, Add } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { textFieldConf } from './textFieldConf';
import { CardAssociateTerm } from '../index';
import { SelectDropDown } from '../../../../components';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const View = (props) => {
    const {
        handleClick,
        listTerm,
        onChangeData,
        listAutoComplete,
        handleOnChangeAutoComplete,
        autoPayPlan,
        getEvent,
        listPayPlan,
        handlePositiveClick,
        handleNegativeClick,
        arrayAllAssociate,
        newObjInArrayAssociate,
        onChangeDataArray,
        onDeleteAssociateCard,
        expanded,
        handleExpandClick,
        add,
        openConfirmDialog,
        onDelete,
    } = props;
    const classes = useStyles();
    const accionDialog = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        openConfirmDialog(null, false);
                    }}
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onDelete();
                    }}
                    color='primary'
                >
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <Paper className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        {textFieldConf.map((obj, i) => {
                            return (
                                <Grid
                                    item
                                    key={i}
                                    xl={obj.xl}
                                    lg={obj.lg}
                                    md={obj.md}
                                    sm={obj.sm}
                                    xs={obj.xs}
                                >
                                    <TextField
                                        disabled={!handleClick.edit}
                                        label={obj.name}
                                        name={obj.name}
                                        value={listTerm[obj.name]}
                                        onChange={onChangeData}
                                        variant='outlined'
                                        size='small'
                                    />
                                </Grid>
                            );
                        })}
                        {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <Autocomplete
                                key={handleClick.key}
                                id="combo-box-demo"
                                options={listAutoComplete}
                                size="small"
                                disabled={add}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => handleOnChangeAutoComplete(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="associate" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={3} xs={3}>
                            <SelectDropDown
                                labelName={'Pay Plan'}
                                nameSelect={'payplan'}
                                valueProp={
                                    autoPayPlan.associate
                                        ? listTerm.ASSOCIATES[autoPayPlan.index].payplan
                                        : ''
                                }
                                onChangeProp={getEvent}
                                objectList={listPayPlan}
                                keyValue={'NAME'}
                                keyLabel={'NAME'}
                                disableValue={add ? add : !handleClick.edit}
                            />
                        </Grid> */}

                        {add && (
                            <>
                                <IconButton onClick={handlePositiveClick}>
                                    <AddCircleRoundedIcon fontSize='large' color='secondary' />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        handleNegativeClick(accionDialog());
                                    }}
                                >
                                    <Clear fontSize='small' />
                                </IconButton>
                            </>
                        )}
                    </Grid>
                </CardContent>
                {!add && (
                    <>
                        <CardActions disableSpacing>
                            <IconButton onClick={handlePositiveClick}>
                                {handleClick.edit ? (
                                    <Check fontSize='small' />
                                ) : (
                                    <Edit fontSize='small' />
                                )}
                            </IconButton>

                            <IconButton
                                onClick={() => {
                                    handleNegativeClick(accionDialog());
                                }}
                            >
                                {handleClick.edit ? (
                                    <Clear fontSize='small' />
                                ) : (
                                    <DeleteOutline fontSize='small' />
                                )}
                            </IconButton>

                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>

                        <Collapse in={expanded} timeout='auto' unmountOnExit>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                                        <CardAssociateTerm
                                            isDisable={!handleClick.edit}
                                            listPayPlan={listPayPlan}
                                            arrayAllAssociate={arrayAllAssociate}
                                            add={true}
                                            newObjInArrayAssociate={newObjInArrayAssociate}
                                            onClean={handleClick.cleanCardTerm}
                                        />
                                    </Grid>
                                    {listTerm.ASSOCIATES.map((obj, index) => {
                                        return (
                                            <Grid
                                                key={index}
                                                item
                                                xl={3}
                                                lg={4}
                                                md={4}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CardAssociateTerm
                                                    obj={obj}
                                                    isDisable={!handleClick.edit}
                                                    posicionArray={index}
                                                    handleChange={onChangeDataArray}
                                                    listPayPlan={listPayPlan}
                                                    arrayAllAssociate={arrayAllAssociate}
                                                    onDeleteAssociateCard={onDeleteAssociateCard}
                                                    onClean={handleClick.cleanCardTerm}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </>
                )}
            </Card>
        </Paper>
    );
};

View.propTypes = {
    handleClick: PropTypes.object.isRequired,
    listTerm: PropTypes.object.isRequired,
    onChangeData: PropTypes.func.isRequired,
    listAutoComplete: PropTypes.array.isRequired,
    handleOnChangeAutoComplete: PropTypes.func.isRequired,
    autoPayPlan: PropTypes.object.isRequired,
    getEvent: PropTypes.func.isRequired,
    listPayPlan: PropTypes.array.isRequired,
    handlePositiveClick: PropTypes.func.isRequired,
    handleNegativeClick: PropTypes.func.isRequired,
    arrayAllAssociate: PropTypes.func.isRequired,
    newObjInArrayAssociate: PropTypes.func.isRequired,
    onChangeDataArray: PropTypes.func.isRequired,
    onDeleteAssociateCard: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandClick: PropTypes.func.isRequired,
    add: PropTypes.bool.isRequired,
    openConfirmDialog: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
View.defaultProps = {};

export default View;
