import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    fieldSearchA: {
        width: 400,
    },
    fieldSearchB: {
        paddingLeft: theme.spacing(1),
        width: 200,
    },
    actionHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    cardContentTMs: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    grid: {
        padding: theme.spacing(2),
    },
    fabPrint: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 5,
        left: 'auto',
        position: 'fixed',
        zIndex: 1,
    },
    radioGroup: {
        margin: 'auto 20px',
    },
}));
