import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { token } from '../../redux/selectors';
import PropTypes from 'prop-types';

const RouteWithLayout = (props) => {
    const responsetoken = useSelector((state) => token(state));
    const { layout: Layout, component: Component, reducerPath, ...rest } = props;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                responsetoken ? (
                    <Layout>
                        <Component reducer={reducerPath} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    reducerPath: PropTypes.string,
};
RouteWithLayout.defaultProps = {
    reducerPath: '',
};

export default RouteWithLayout;
