export const textFieldConf = [
    {
        xl: 2,
        lg: 2,
        md: 2,
        sm: 4,
        xs: 3,
        name: 'NAME',
        value: 'NAME',
    },
    {
        xl: 1,
        lg: 1,
        md: 1,
        sm: 4,
        xs: 3,
        name: 'INT',
        value: 'DAY',
    },

    {
        xl: 1,
        lg: 1,
        md: 1,
        sm: 4,
        xs: 3,
        name: 'MONTH',
        value: 'MONTH',
    },
    {
        xl: 1,
        lg: 1,
        md: 1,
        sm: 4,
        xs: 3,
        name: 'YEAR',
        value: 'YEAR',
    },
];
