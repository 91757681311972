import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content',
    },
    list: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    item: {
        display: 'flex',
        padding: 0,
    },
    button: {
        color: colors.blueGrey[800],
        padding: '5px 2px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
    nested: {
        display: 'flex',
        paddingLeft: theme.spacing(1),
    },
    divTerm: {
        display: 'flex',
        flexDirection: 'row',
    },
    dialog: {
        width: 300,
    },
}));
