import React from 'react';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import { CreateAssociate, ListAssociate } from './components';
import { DialogMessage } from '../../components';

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <DialogMessage />
            <Grid container spacing={4}>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                    <CreateAssociate />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                    <ListAssociate />
                </Grid>
            </Grid>
        </div>
    );
};
