import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    content: {
        height: 100,
    },
    rightBotton: {
        marginLeft: 'auto',
    },
    leftButton: {
        marginRight: 'auto',
    },
    textField: {
        paddingBottom: theme.spacing(1),
    },
    heightEmpy: {
        height: 60,
    },
    addIcon: {
        fontSize: 75,
    },
    addDiv: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
    },
}));
