import {
    GET_TM_START,
    ADD_MEMBER_TO_TM_START,
    DELETE_MEMBER_TO_TM_START,
} from '../../consts/actionTypes';

export const getTMListAction = (term_name, tm_id) => ({
    type: GET_TM_START,
    payload: {
        term_name,
        tm_id,
    },
});

export const addMemberToTMAction = (term_name, tm_id, associate) => ({
    type: ADD_MEMBER_TO_TM_START,
    payload: {
        term_name,
        tm_id,
        body: {
            associate_id: associate.dms_number,
        },
    },
});

export const deleteMemberOfTMAction = (term_name, tm_id, associate_id) => ({
    type: DELETE_MEMBER_TO_TM_START,
    payload: {
        term_name,
        tm_id,
        body: {
            associate_id,
        },
    },
});
