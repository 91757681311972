import { GET_DEAL_COMPLETE,UPDATE_DEAL,DISCARD_DEAL_CHANGES,GET_TRANSFER_DEAL_COMPLETE } from '../../consts/actionTypes';

const initialState = {
    sp:[],
    fim:[],
    deal:[],
};

const tempState = {
    sp:[],
    fim:[],
    deal:[],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEAL_COMPLETE:
            tempState.sp = action.data.sp
            tempState.fim = action.data.fim
            tempState.deal = action.data.deal
            return {
                ...state,
                sp: action.data.sp,
                fim: action.data.fim,
                deal: action.data.deal,
            };
        case UPDATE_DEAL:
            return {
                ...state,
                deal: state.deal.map((deal) => {return deal._id === action.payload._id ? {...deal,...action?.payload} : deal }),
            };
        case GET_TRANSFER_DEAL_COMPLETE:
            return {
                ...state,
                deal: action.data,
            };
        case DISCARD_DEAL_CHANGES:
            return {
                ...state,
                deal: tempState.deal,
            };
        default:
            return state;
    }
}
