export const mapData = {
    customerData: [
        {
            code: 'FIWI',
            description: 'Deal Recap : ',
            value: '',
        },
        {
            code: 'DASO',
            description: 'Contract Date : ',
            value: '',
        },
        {
            code: 'BUNU',
            description: 'Customer Number : ',
            value: '',
        },
        {
            code: 'BUNA',
            description: 'Customer Name : ',
            value: '',
        },
        {
            code: 'BUYSTR',
            description: 'Street : ',
            value: '',
        },
        {
            code: 'BUCI',
            description: 'City : ',
            value: '',
        },
        {
            code: 'BUCO',
            description: 'County : ',
            value: '',
        },
        {
            code: 'BUST',
            description: 'State : ',
            value: '',
        },
        {
            code: 'BUZICO',
            description: 'Zip Code : ',
            value: '',
        },
        {
            code: 'BUPH1',
            description: 'Home Phone : ',
            value: '',
        },
        {
            code: 'BUPH2',
            description: 'Work Phone : ',
            value: '',
        },
        {
            code: 'EMAAD1',
            description: 'Main Mail : ',
            value: '',
        },
    ],
    basicDataDeal: [
        {
            code: 'VESTNO',
            description: 'Stock# : ',
            value: '',
        },
        {
            code: 'MAVE',
            description: 'Maker : ',
            value: '',
        },
        {
            code: 'MOVE',
            description: 'Model : ',
            value: '',
        },
        {
            code: 'FICONA',
            description: 'Finance Institution : ',
            value: '',
        },
        {
            code: 'COTE',
            description: 'Quota : ',
            value: '',
        },
        {
            code: 'MOPA',
            description: 'Monthly Payments : ',
            value: '',
        },
        {
            code: 'SENOVE',
            description: 'VIN : ',
            value: '',
        },
        {
            code: 'TR1SENO',
            description: 'VIN2 : ',
            value: '',
        },
        {
            code: 'DETY',
            description: 'Deal Type/ Reserved Method : ',
            value: '',
        },
        {
            code: 'FITO',
            description: 'Amount Finance : ',
            value: '',
        },
    ],
    grossProfitAnalysis: {
        front: [],
        back: [],
        other: [],
        taxes: [],
        totalback: {
            Title: 'Total Back Sale',
            Description: '',
            Sale: 0,
            Cost: 0,
            Gross: 0,
        },
        totalfront: {
            Title: 'Total Front Sale',
            Description: '',
            Sale: 0,
            Cost: 0,
            Gross: 0,
        },
        totalsale: {
            Title: 'Total Sale',
            Description: '',
            Sale: 0,
            Cost: 0,
            Gross: 0,
        },
    },
    commissions: [],
};
