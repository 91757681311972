// Types
export const TOKEN_NAME = 'hdcar_access_token';
export const REFRESH_TOKEN = 'hdcar_refresh_token';

const isBrowser = typeof window !== 'undefined';

export const setLocalStorage = (value, name = TOKEN_NAME) =>
    isBrowser && window.sessionStorage.setItem(name, value);

export const getLocalStorage = (name = TOKEN_NAME) =>
    isBrowser && window.sessionStorage.getItem(name);

export const rmLocalStorage = (name = TOKEN_NAME) =>
    isBrowser && window.sessionStorage.removeItem(name);
