import React, { useState, useEffect } from 'react';
import View from './view';
import { useDispatch, useSelector } from 'react-redux';
import {
    addUnitAdjustmentAction,
    editUnitAdjustmentAction,
    deleteUnitAdjustmentAction,
} from '../../../../redux/actions/adjustmentAction';
import {
    dmsCurrentWashoutSelector,
    dateCurrentWashoutSelector,
    typeAssoCurrentWashoutSelector,
    unitAdjustmentSelector,
} from '../../../../redux/selectors';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';

export default () => {
    const dispatch = useDispatch();
    const dmsCurrentState = useSelector((state) => dmsCurrentWashoutSelector(state));
    const dateCurrentState = useSelector((state) => dateCurrentWashoutSelector(state));
    const typeCurrentState = useSelector((state) => typeAssoCurrentWashoutSelector(state));
    const unitAdjustmentState = useSelector((state) => unitAdjustmentSelector(state));

    const [addCar, setAddCar] = useState({
        description: '',
        new: '0',
        used: '0',
    });
    const [listUnitAdjustment, setlistUnitAdjustment] = useState(unitAdjustmentState);
    useEffect(() => {
        setlistUnitAdjustment(unitAdjustmentState);
    }, [unitAdjustmentState]);

    const handleChangeAddCar = (e) => {
        setAddCar({
            ...addCar,
            [e.target.name]: e.target.value,
        });
    };
    const handleChangeUnit = (e, index) => {
        const editItem = { ...listUnitAdjustment[index], [e.target.name]: e.target.value };
        const newList = [
            ...listUnitAdjustment.slice(0, index),
            editItem,
            ...listUnitAdjustment.slice(index + 1),
        ];
        setlistUnitAdjustment(newList);
    };
    const onAddCar = () => {
        if (
            dmsCurrentState &&
            dateCurrentState &&
            addCar.description &&
            addCar.new &&
            addCar.used
        ) {
            let objAddCar = {
                assoc_number: dmsCurrentState,
                month: dateCurrentState,
                new: addCar.new,
                used: addCar.used,
                description: addCar.description,
            };

            if (typeCurrentState == 0 || typeCurrentState === '2') {
                dispatch(isLoadingAction(true));
                dispatch(addUnitAdjustmentAction(objAddCar));
            }
            setAddCar({
                description: '',
                new: '0',
                used: '0',
            });
        }
    };
    const onEdit = (index) => {
        dispatch(isLoadingAction(true));
        dispatch(
            editUnitAdjustmentAction(listUnitAdjustment[index], listUnitAdjustment[index]._id)
        );
    };
    const onDelete = (index) => {
        dispatch(isLoadingAction(true));
        dispatch(deleteUnitAdjustmentAction(unitAdjustmentState[index]._id));
    };

    console.log(
        '[listUnitAdjustment]',
        listUnitAdjustment,
        '[addCar]',
        addCar,
        '[dmsCurrentState]',
        dmsCurrentState
    );
    return (
        <View
            addCar={addCar}
            handleChangeAddCar={handleChangeAddCar}
            onAddCar={onAddCar}
            onEdit={onEdit}
            onDelete={onDelete}
            listUnitAdjustment={listUnitAdjustment}
            handleChangeUnit={handleChangeUnit}
        />
    );
};
