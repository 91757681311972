import {
    ADJUSTMENT_LIST_START,
    ADJUSTMENT_SINGLE_START,
    ADJUSTMENT_CREATE_START,
    ADJUSTMENT_UPDATE_START,
    ADJUSTMENT_DELETE_START,
    ADJUSTMENT_OPEN_DILOG,
    ADJUSTMENT_SINGLE,
    CLEAN_ADJUSTMENT_WASHOUT,
    CREATE_UNIT_ADJUSTMENT_START,
    EDIT_UNIT_ADJUSTMENT_START,
    DELETE_UNIT_ADJUSTMENT_START,
} from '../../consts/actionTypes';

export const adjustmentListAction = () => ({
    type: ADJUSTMENT_LIST_START,
});
export const adjustmentUpdateAction = (payload, obj) => ({
    type: ADJUSTMENT_UPDATE_START,
    payload,
    obj,
});
export const adjustmentDeleteAction = (idItem, obj) => ({
    type: ADJUSTMENT_DELETE_START,
    idItem,
    obj,
});
export const adjustmentSingleSearchAction = (payload) => ({
    type: ADJUSTMENT_SINGLE_START,
    payload,
});
export const adjustmentCreateAction = (payload, obj) => ({
    type: ADJUSTMENT_CREATE_START,
    payload,
    obj,
});
export const adjustmentOpenDialogAction = (open, action) => ({
    type: ADJUSTMENT_OPEN_DILOG,
    open,
    action,
});
export const adjustmentSingleAction = () => ({
    type: ADJUSTMENT_SINGLE,
});
export const cleanAdjustmentWashout = () => ({
    type: CLEAN_ADJUSTMENT_WASHOUT,
});
export const addUnitAdjustmentAction = (objAddCar) => ({
    type: CREATE_UNIT_ADJUSTMENT_START,
    objAddCar,
});
export const editUnitAdjustmentAction = (objAddCar, id) => ({
    type: EDIT_UNIT_ADJUSTMENT_START,
    objAddCar,
    id,
});
export const deleteUnitAdjustmentAction = (id) => ({
    type: DELETE_UNIT_ADJUSTMENT_START,
    id,
});
