import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import {
    ListItem,
    Button,
    List,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Collapse,
    TextField,
} from '@material-ui/core';
import { SelectDropDown } from '../../../../../../components';
import { months, years } from '../../../../../../consts/date';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { messageButtons } from './messageButtons';
import { SUBDEALS, TEAM_MANAGER_DRAW } from '../../../../../../consts/urls';

const View = (props) => {
    const {
        handleNestedClick,
        openNested,

        onClickMessageButton,
        dataExtraction,
        onChangeDataExtraction,
        dialogMessageData,
        handleCloseDilogMessage,
        onClickExtraction,
    } = props;
    const classes = useStyles();

    const deal = () => {
        return (
            <div>
                <TextField
                    label='deal'
                    name='deal'
                    size='small'
                    value={dataExtraction.deal}
                    onChange={onChangeDataExtraction}
                    variant='outlined'
                />
            </div>
        );
    };
    const term = () => {
        return (
            <div className={classes.divTerm}>
                <SelectDropDown
                    labelName={'Month'}
                    nameSelect={'month'}
                    valueProp={dataExtraction.month}
                    onChangeProp={onChangeDataExtraction}
                    objectList={months}
                    keyValue={'code'}
                    keyLabel={'name'}
                />
                <SelectDropDown
                    labelName={'Year'}
                    nameSelect={'year'}
                    valueProp={dataExtraction.year}
                    onChangeProp={onChangeDataExtraction}
                    objectList={years}
                    keyValue={'code'}
                    keyLabel={'name'}
                />
            </div>
        );
    };
    const isOpen = openNested === 'messageMenu';
    return (
        <div>
            <Dialog
                open={dialogMessageData.open}
                keepMounted
                onClose={handleCloseDilogMessage}
                fullWidth={true}
            >
                <DialogTitle>{dialogMessageData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessageData.text}</DialogContentText>
                    {dialogMessageData.action === 'term' ||
                    dialogMessageData.action === 'termTMDraws' ? (
                        term()
                    ) : (
                        <div />
                    )}
                    {dialogMessageData.action === 'deal' ? deal() : <div />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickExtraction}>
                        {dialogMessageData.endPoint === SUBDEALS ||
                        dialogMessageData.endPoint === TEAM_MANAGER_DRAW
                            ? 'Create'
                            : 'Extration'}
                    </Button>
                </DialogActions>
            </Dialog>
            <List className={classes.list}>
                <ListItem className={classes.item} disableGutters>
                    <Button
                        className={classes.button}
                        onClick={() => handleNestedClick('messageMenu')}
                    >
                        <div className={classes.icon}>{messageButtons.icon}</div>
                        {messageButtons.title}
                    </Button>
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {messageButtons.children.map((children) => {
                            return (
                                <ListItem
                                    className={classes.nested}
                                    disableGutters
                                    key={children.title}
                                >
                                    <Button
                                        className={classes.button}
                                        onClick={() => {
                                            onClickMessageButton(children);
                                        }}
                                    >
                                        <div className={classes.icon}>{children.icon}</div>
                                        {children.title}
                                    </Button>
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            </List>
        </div>
    );
};
View.prototype = {
    openNested: PropTypes.bool.isRequired,
    handleChangeOpenNested: PropTypes.func.isRequired,
    onClickMessageButton: PropTypes.func.isRequired,
    dataExtraction: PropTypes.object.isRequired,
    onChangeDataExtraction: PropTypes.func.isRequired,
    dialogMessageData: PropTypes.object.isRequired,
    handleCloseDilogMessage: PropTypes.func.isRequired,
    onClickExtraction: PropTypes.func.isRequired,
};
export default View;
