export const typeAssociate = [
    {
        code: 'Seller',
        name: 'Seller',
    },
    {
        code: 'FIM',
        name: 'FIM',
    },
    {
        code: 'TM',
        name: 'TM',
    },
];
