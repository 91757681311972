import React, { useEffect } from 'react';
import {
    validateDateFormat,
    haveAllKeysInObj,
    validateFieldEmptyInObj,
} from '../../consts/fuctions';
import { months } from '../../consts/date';
import { useDispatch, useSelector } from 'react-redux';
import {
    adjustmentListAction,
    adjustmentDeleteAction,
    adjustmentCreateAction,
    adjustmentUpdateAction,
} from '../../redux/actions/adjustmentAction';
import { isLoadingAction, snackBarAction } from '../../redux/actions/utilitiesAction';
import { listResponseSelector, token, isLoading } from '../../redux/selectors';

import View from './view';

export default () => {
    const dispatch = useDispatch();
    const listResponse = useSelector((state) => listResponseSelector(state, 'adjustmentReducer'));
    const responseToken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));

    useEffect(() => {
        if (!listResponse && responseToken) {
            dispatch(isLoadingAction(true));
            dispatch(adjustmentListAction());
        }
    }, [listResponse, responseToken, dispatch]);

    const onRowAdd = (obj, newData, data) => {
        let endRow = { ...data };
        delete endRow.ADJ_ID;
        delete endRow.tableData;
        delete endRow._id;
        //newData = { ...newData, ADJ_DATE: '' }

        //if (validateDateFormat(newData.ACCTGDATE, dispatch, snackBarAction)) {
        /* let date = newData.ACCTGDATE;
     let arrayDate = date.split("/", 3);
     let month = parseInt(arrayDate[0]);
     let adjDate = `${months[month - 1].code}${arrayDate[2]}`
     newData = { ...newData, ADJ_DATE: adjDate }*/
        dispatch(isLoadingAction(true));
        dispatch(adjustmentCreateAction(newData, obj));
        // }
    };
    const onRowDelete = (obj, oldData) => {
        dispatch(isLoadingAction(true));
        dispatch(adjustmentDeleteAction(oldData._id, obj));
    };
    const onRowEdit = (obj, newData, oldData) => {
        let data = { ...newData };
        let firstchar = data.POSTAMTEXT.charAt(0);
        if (firstchar === '$') {
            data = {
                ...data,
                POSTAMTEXT: data.POSTAMTEXT.slice(1),
            };
        }
        dispatch(isLoadingAction(true));
        dispatch(adjustmentUpdateAction(data, obj));
    };

    return (
        <View
            isLoadingResponse={isLoadingResponse}
            onRowAdd={onRowAdd}
            onRowDelete={onRowDelete}
            onRowEdit={onRowEdit}
        />
    );
};
