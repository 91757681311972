import { get } from 'lodash';
import { PictureAsPdfRounded } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { PAYPLAN_LIST_COMPLETE, PAYPLAN_LIST_ERROR } from '../../consts/actionTypes';
const payplanTable = {
    columns: [
        {
            title: 'Description',
            field: 'DESCRIPTION',
            editComponent: (props) => (
                <TextField
                    onChange={(e) => props.onChange(e.target.value)}
                    id='standard-basic'
                    label='Description'
                    variant='outlined'
                    style={{ width: 250 }}
                    value={props.value}
                />
            ),
        },
        {
            title: 'View',
            field: 'VIEW',
            editable: 'never',
            render: () => <PictureAsPdfRounded />,
        },
        {
            title: 'Status',
            field: 'STATUS',
            editable: 'never',
        },
    ],
    data: [],
};
const initialState = {
    payplanTable: payplanTable,
    listResponse: null,
    singleResponse: null,
    openDialogAction: false,
    actionDialog: 0,
    dinamicTableConf: {
        ...payplanTable,
        columns: [
            {
                title: 'Name',
                field: 'NAME',
                editable: 'onAdd',
                editComponent: (props) => (
                    <TextField
                        onChange={(e) => props.onChange(e.target.value)}
                        id='standard-basic'
                        label='NAME'
                        variant='outlined'
                        style={{ width: 250 }}
                        value={props.value}
                    />
                ),
            },
            ...payplanTable.columns,
        ],
    },
    associateWashout: { ...payplanTable },
};

const newData = (data) => {
    if (!data.length) return [];

    for (const objPayplan of data) {
        objPayplan.POSTAMTEXT = `$${new Intl.NumberFormat().format(objPayplan.POSTAMTEXT)}`;
    }
    return data;
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYPLAN_LIST_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'results.data'),
                dinamicTableConf: {
                    ...state.dinamicTableConf,
                    data: [...newData(action?.results?.data)],
                },
            };

        default:
            return { ...state };
    }
}
