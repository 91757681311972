import { put, call, takeLatest, select } from 'redux-saga/effects';

import { apiCall } from '../api';
import { token } from '../selectors';
import { snackBarAction, isLoadingAction } from '../actions/utilitiesAction';
import { TM } from '../../consts/urls';
import {
    GET_TM_START,
    GET_TM_COMPLETE,
    ADD_MEMBER_TO_TM_START,
    DELETE_MEMBER_TO_TM_START,
} from '../../consts/actionTypes';

function* getMemebers({ payload }) {
    try {
        const accessToken = yield select(token);
        const response = yield call(
            apiCall,
            `${TM}/${payload.term_name}/${payload.tm_id}`,
            null,
            accessToken,
            'GET'
        );

        yield put({ type: GET_TM_COMPLETE, data: response.data });
    } catch (error) {
        const errorResponse = error.response;

        yield put(
            snackBarAction({
                open: true,
                msg: 'error Load List: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }

    yield put(isLoadingAction(false));
}

function* addMemberToTM({ payload }) {
    try {
        const accessToken = yield select(token);
        const response = yield call(
            apiCall,
            `${TM}/${payload.term_name}/${payload.tm_id}`,
            payload.body,
            accessToken,
            'POST'
        );

        yield put(
            snackBarAction({
                open: true,
                msg: response?.data?.message,
                type: 'success',
            })
        );

        yield put({ type: GET_TM_START, payload });
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error' + errorResponse?.data?.message,
                type: 'error',
            })
        );
    }

    yield put(isLoadingAction(false));
}

function* deleteMemberOfTmRemote({ payload }) {
    try {
        const accessToken = yield select(token);
        const response = yield call(
            apiCall,
            `${TM}/${payload.term_name}/${payload.tm_id}`,
            payload.body,
            accessToken,
            'DELETE'
        );

        yield put(
            snackBarAction({
                open: true,
                msg: response?.data?.message,
                type: 'success',
            })
        );

        yield put({ type: GET_TM_START, payload });
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error' + errorResponse?.data?.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* TMwatch() {
    yield takeLatest(GET_TM_START, getMemebers);
    yield takeLatest(ADD_MEMBER_TO_TM_START, addMemberToTM);
    yield takeLatest(DELETE_MEMBER_TO_TM_START, deleteMemberOfTmRemote);
}
