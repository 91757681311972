import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        flex: 1,
        height: '100%',
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/backgroundLogin.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px',
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300,
    },
}));
