import React, { useRef, useMemo } from 'react';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Fab, Box } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { PreViewPrint } from '../../../../components';
import { listResponseSelector } from '../../../../redux/selectors';
const Index = (props) => {
    const { allWashout, dateSearch } = props;

    const refBoxPrintAllWashout = useRef();

    const AllPrint = useMemo(() => {
        return (
            <Box display='none'>
                <div ref={refBoxPrintAllWashout}>
                    {allWashout.map((obj, index) => {
                        return (
                            <div style={{ marginTop: 5 }} key={index}>
                                {obj.associateNumber.type == 'Seller' ? (
                                    <PreViewPrint
                                        dmsData={obj.associateNumber}
                                        bonus={obj.respondeBonus}
                                        summary={obj.summary}
                                        payroll={obj.payroll}
                                        dataTableDeals={obj.dinamicTableConf}
                                        dataTableDeals2={null}
                                        dataTableAdjustment={obj.dataTableAdjustment}
                                        dataTableDealsNew={obj.dinamicTableConfNew}
                                        dataTableDealsUsed={obj.dinamicTableConfUsed}
                                        dateReport={dateSearch}
                                    />
                                ) : (
                                    <PreViewPrint
                                        dmsData={obj.associateNumber}
                                        bonus={obj.respondeBonus}
                                        summary={obj.summary}
                                        payroll={obj.payroll}
                                        dataTableDeals={obj.dinamicTableConf}
                                        dataTableDeals2={obj.dinamicTableConf2}
                                        dataTableAdjustment={obj.dataTableAdjustment}
                                        dataTableDealsNew={null}
                                        dataTableDealsUsed={null}
                                        dateReport={dateSearch}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </Box>
        );
    }, [allWashout]);
    return (
        <div>
            <ReactToPrint
                trigger={() => (
                    <Fab size='small'>
                        <PrintOutlinedIcon fontSize='small' />
                    </Fab>
                )}
                content={() => refBoxPrintAllWashout.current}
            />
            {AllPrint}
        </div>
    );
};

Index.propTypes = {
    allWashout: PropTypes.array.isRequired,
};
Index.defaultProps = {};

export default Index;
