import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { snackBarSelector } from '../../redux/selectors';
import { snackBarAction } from '../../redux/actions/utilitiesAction';

export default () => {
    const snackBar = useSelector((state) => snackBarSelector(state));
    const dispath = useDispatch();

    const closeSanckBar = () => {
        dispath(
            snackBarAction({
                open: false,
                msg: '',
                type: snackBar.type,
            })
        );
    };
    return (
        <Snackbar
            open={snackBar.open}
            autoHideDuration={3000}
            onClose={closeSanckBar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert
                elevation={6}
                variant='filled'
                onClose={closeSanckBar}
                severity={snackBar.type}
            >
                {snackBar.msg}
            </MuiAlert>
        </Snackbar>
    );
};
