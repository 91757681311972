import React from 'react';
import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
} from '@material-ui/core';

export const CardDetail = ({ titleCard, children }) => {
    return (
        <Card>
            <CardHeader title={titleCard} />
            <Divider />
            <CardContent>
                <TableContainer>
                    <Table size='small'>
                        <TableBody>{children}</TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export const CardTableMultiRow = ({ title, object }) => {
    return (
        <CardDetail titleCard={title}>
            {Object.keys(object).map((row, indexRow) => {
                return (
                    <TableRow key={indexRow}>
                        <TableCell size='small' padding='none' align='left'>
                            {row}
                        </TableCell>
                        <TableCell size='small' padding='none' align='right'>
                            {object[row]}
                        </TableCell>
                    </TableRow>
                );
            })}
        </CardDetail>
    );
};
