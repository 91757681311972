import { get } from 'lodash';
import {
    IS_LOADING,
    SNACKBAR,
    DIALOGMESSAGE,
    ASSOCIATE_NAME_LIST,
    ID_ITEM_SELECTED,
} from '../../consts/actionTypes';
const initialState = {
    isLoading: false,
    snackBar: {
        open: false,
        msg: '',
        type: '',
    },
    dialogMessage: {
        open: false,
        title: '',
        text: '',
        children: null,
    },
    associateNameList: null,
    idItemSalected: null,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, isLoading: get(action, 'isloading') };
        case SNACKBAR:
            return { ...state, snackBar: get(action, 'snackBar') };
        case DIALOGMESSAGE:
            return { ...state, dialogMessage: get(action, 'payload') };
        case ASSOCIATE_NAME_LIST:
            return { ...state, associateNameList: get(action, 'associateNameList.data') };
        case ID_ITEM_SELECTED:
            return { ...state, idItemSalected: get(action, 'payload') };
        default:
            return { ...state };
    }
}
