import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },

    Icons: {
        fontSize: 'larger',
    },

    button: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    printiconbutton: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: '-20px',
        },
        textAlign: 'right',
    },
    spacing: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginLeft: '0px',
        },
        marginLeft: '30px',
    },

    fabPrint: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 5,
        left: 'auto',
        position: 'fixed',
        zIndex: 1,
    },

    fabClose: {
        margin: 0,
        top: 5,
        right: 20,
        bottom: 'auto',
        left: 'auto',
        position: 'fixed',
        zIndex: 3,
    },
    tabs: {
        padding: 0,
    },
    tab: {
        padding: 0,
        height: 28,
        width: 40,
    },
}));
