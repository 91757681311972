import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import View from './view';
import { signIn } from '../../redux/actions/loginAction';
import { isLoadingAction } from '../../redux/actions/utilitiesAction';
import { token, isLoading } from '../../redux/selectors';

const SignIn = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const responsetoken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));
    const [login, setLogin] = useState({
        username: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        if (responsetoken) {
            history.push('/commission_pages');
        }
    }, [responsetoken, history]);

    const onChangeLogin = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value,
        });
    };

    const handleSignIn = () => {
        dispatch(isLoadingAction(true));
        dispatch(signIn(login));
    };

    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onEnter = (e) => {
        if (e.key == 'Enter') {
            handleSignIn();
        }
    };
    return (
        <View
            login={login}
            onChangeLogin={onChangeLogin}
            handleSignIn={handleSignIn}
            isLoadingResponse={isLoadingResponse}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            onEnter={onEnter}
        />
    );
};

SignIn.propTypes = {
    history: PropTypes.object,
};

export default withRouter(SignIn);
