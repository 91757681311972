import { get } from 'lodash';
/**Globales utilidades */
export const isLoading = (state) => get(state, 'utilitiesReducer.isLoading');
export const idItemSelectedSelector = (state) => get(state, 'utilitiesReducer.idItemSalected');
export const dialogMessage = (state) => get(state, 'utilitiesReducer.dialogMessage');
export const snackBarSelector = (state) => get(state, 'utilitiesReducer.snackBar');
export const associateNameListSelector = (state) =>
    get(state, 'utilitiesReducer.associateNameList');
export const listResponseSelector = (state, reducer) => get(state, `${reducer}.listResponse`);
export const dinamicTableSelector = (state, reducer, object) => get(state, `${reducer}.${object}`);
export const dinamicSelector = (state, reducer, object) => get(state, `${reducer}.${object}`);
/**--------- */

/**-----login */

export const token = (state) => get(state, 'loginReducer.token');

/***------- */

/**-----associate */
export const listNameCodeAssociateSelector = (state) => get(state, 'associateReducer.listNameCode');
export const confCardAssociate = (state) => get(state, 'associateReducer.confCardAssociate');
export const associateSelector = (state) => get(state, 'associateReducer.associate');

/**--------- */

/**------- washout*/
export const listResponseDataSubDealsSelector = (state) =>
    get(state, 'washoutReducer.listResponseData');
export const responseTotalsSubDealsSelector = (state) =>
    get(state, 'washoutReducer.listResponseTotals');
export const listResponseAdjustmentsSubDealsSelector = (state) =>
    get(state, 'washoutReducer.listResponseAdjustments');
export const respondeBonusSelector = (state) => get(state, 'washoutReducer.respondeBonus');
export const summaryWashoutSelector = (state) => get(state, 'washoutReducer.summary');
export const payrollWashoutSelector = (state) => get(state, 'washoutReducer.payroll');
export const dmsCurrentWashoutSelector = (state) => get(state, 'washoutReducer.dms');
export const dateCurrentWashoutSelector = (state) => get(state, 'washoutReducer.date');
export const typeAssoCurrentWashoutSelector = (state) => get(state, 'washoutReducer.typeAssociate');
export const unitAdjustmentSelector = (state) => state.washoutReducer.unit_adjustments;
/**---------------- */

/**------------adjustment */
export const singleAdjustmentSelector = (state) => get(state, 'adjustmentReducer.singleResponse');
export const openDialogAdjustmentSelector = (state) =>
    get(state, 'adjustmentReducer.openDialogAction');
export const actionDialogAdjustmentSelector = (state) =>
    get(state, 'adjustmentReducer.actionDialog');

/**---------------------- */

/***subdealapi */
export const keyObjectDataSelector = (state, key) => get(state, `subdealApiReducer.${key}`);
export const urlConfigSelector = (state) => get(state, 'subdealApiReducer.urlConfig');
export const dataChangeSelector = (state) => get(state, 'subdealApiReducer.dataChange');

/**-------- */
/** Search */

export const listOrderDataSelector = (state) => state.searchReducer.listOrderData;
/** */
/**deal API */
export const dealSelector = (state) => state.searchReducer.originalList;

/** reports */

/** transfer deal */
export const transferDealSelector = (state) => state.transferReducer;