import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
    Card,
    CardHeader,
    CardContent,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Button,
    Tabs,
    Tab,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { SimpleProgress } from '../../../../components';

export default (props) => {
    const {
        onCLickDelete,
        onClickEditar,
        listResponse,
        className,
        isLoadingResponse,
        openConfirmDialog,
        indexTab,
        handleChangeTab,
        ...rest
    } = props;

    const classes = useStyles();
    const accionDialog = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        openConfirmDialog(null, false, null);
                    }}
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onCLickDelete();
                    }}
                    color='primary'
                >
                    Delete
                </Button>
            </div>
        );
    };
    return (
        <div className={classes.subDiv}>
            <Card {...rest} className={clsx(classes.root, className)}>
                <Paper className={classes.containerTabs}>
                    <Tabs value={indexTab} onChange={handleChangeTab}>
                        <Tab label='Associate List Seller' />
                        <Tab label='Associate List FIM' />
                        <Tab label='Associate List TM' />
                    </Tabs>
                </Paper>
                <Divider />
                <CardContent className={classes.content}>
                    {isLoadingResponse ? (
                        <SimpleProgress />
                    ) : (
                        <List>
                            {listResponse.length ? (
                                listResponse[indexTab].map((listMap, i) => (
                                    <ListItem divider={i < listMap.length - 1} key={i}>
                                        <ListItemAvatar>
                                            <img
                                                alt='AssociateLogo'
                                                className={classes.image}
                                                src={
                                                    listMap.enabled
                                                        ? 'images/logos/logoUpturnCircle.png'
                                                        : 'images/logos/logoUpturnCircleDisable.png'
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={listMap.name}
                                            secondary={
                                                listMap.enabled
                                                    ? listMap.type
                                                    : `${listMap.type} (Disable)`
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge='end'
                                                aria-label='delete'
                                                onClick={() => {
                                                    openConfirmDialog(
                                                        listMap.dms_number,
                                                        true,
                                                        accionDialog()
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                edge='end'
                                                aria-label='edit'
                                                onClick={() => {
                                                    onClickEditar(listMap);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            ) : (
                                <div />
                            )}
                        </List>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};
