import {
    TERM_UPDATE_START,
    TERM_LIST_START,
    TERM_CREATE_START,
    TERM_DELETE_START,
} from '../../consts/actionTypes';
export const createTermAction = (data) => ({
    type: TERM_CREATE_START,
    data,
});
export const updateTermAction = (id, data) => ({
    type: TERM_UPDATE_START,
    id,
    data,
});
export const listTermAction = () => ({
    type: TERM_LIST_START,
});
export const deleteTermAction = (id) => ({
    type: TERM_DELETE_START,
    id,
});
