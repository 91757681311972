import React from 'react';
import View from './view';
import { useSelector } from 'react-redux';
import {
    respondeBonusSelector,
    summaryWashoutSelector,
    payrollWashoutSelector,
    dmsCurrentWashoutSelector,
    dateCurrentWashoutSelector,
} from '../../../../redux/selectors';

export default (typeAssociate) => {
    const bonus = useSelector((state) => respondeBonusSelector(state));
    const summary = useSelector((state) => summaryWashoutSelector(state));
    const payroll = useSelector((state) => payrollWashoutSelector(state));
    const dms = useSelector((state) => dmsCurrentWashoutSelector(state));
    const date = useSelector((state) => dateCurrentWashoutSelector(state));

    const inputString = date;

    const match = inputString?.match(/^([a-zA-Z]+)(\d+)$/);

    const [month, year] = match ? [match[1], match[2]] : [null, null];
    return (
        <View
            bonus={bonus}
            summary={summary}
            payroll={payroll}
            dms={dms}
            month={month}
            year={year}
            typeAssociate={typeAssociate}
        />
    );
};
