import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 50,
        marginTop: 50,
    },
}));
export default (props) => {
    const { size } = props;
    const classes = useStyles();
    return (
        <div className={classes.progress}>
            <CircularProgress size={size} />
        </div>
    );
};
