import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Button,
    TextField,
    Grid,
    Typography,
    Switch,
} from '@material-ui/core';
import { SelectDropDown } from '../../../../components';
import { typeAssociate } from '../../../../consts/listDropDown';

const storeOptions = [
    { title: "South Dade Kia Homestead", value: "SDKH" },
    { title: "South Dade Kia Miami", value: "SDKM" }
];

export default (props) => {
    const {
        onChangeRegister,
        onCLickRegister,
        register,
        onCLickLimpiar,
        className,
        editAssociate,
        onChangeSwitch,
        ...rest
    } = props;

    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader title={editAssociate.cardTitle} />
            <Divider />
            <CardContent>
                <TextField
                    fullWidth
                    label='Dms Number'
                    name='dms'
                    size='small'
                    value={register.dms}
                    onChange={onChangeRegister}
                    variant='outlined'
                    disabled={editAssociate.textFildDms}
                    className={classes.textField}
                />
                <TextField
                    fullWidth
                    label='Name'
                    name='name'
                    size='small'
                    value={register.name}
                    onChange={onChangeRegister}
                    variant='outlined'
                    className={classes.textField}
                />
                <SelectDropDown
                    labelName={'Type'}
                    nameSelect={'type'}
                    valueProp={register.type}
                    onChangeProp={onChangeRegister}
                    objectList={typeAssociate}
                    keyValue={'code'}
                    keyLabel={'name'}
                    className={classes.textField}
                />
                <SelectDropDown
                    labelName={'Store'}
                    nameSelect={'STORE'}
                    valueProp={register.STORE}
                    onChangeProp={onChangeRegister}
                    objectList={storeOptions}
                    keyValue={'value'}
                    keyLabel={'title'}
                />
                <Typography component='div'>
                    <Grid component='label' container alignItems='center' spacing={1}>
                        <Typography>Active :</Typography>
                        <Grid item>Disable</Grid>
                        <Grid item>
                            <Switch checked={register.enabled} onChange={onChangeSwitch} />
                        </Grid>
                        <Grid item>Enable</Grid>
                    </Grid>
                </Typography>

                <Grid className={classes.gridButton} container spacing={1}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Button
                            color='secondary'
                            fullWidth
                            variant='contained'
                            onClick={onCLickRegister}
                            disabled={!register.name || !register.dms || !register.type}
                        >
                            {editAssociate.buttonCreate}
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Button
                            color='primary'
                            fullWidth
                            variant='contained'
                            onClick={onCLickLimpiar}
                        >
                            Clean
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
