import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    tableContainer: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
    },
    table: {
        paddingTop: 20,
        paddingBottom: 15,
        marginBottom: 100,
    },
    cell: {
        padding: 0,
        paddingRight: 2,
        marginTop: 1,
    },
    paperPrint: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'column',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content: {
        height: '100vh',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },

    border: {
        padding: theme.spacing(1),
        height: 30,
    },
    subTitle: {
        paddingBottom: 20,
    },
    alignLeft: {
        textAlign: 'left !important',
    },
    alignRight: {
        textAlign: 'right',
    },
}));
