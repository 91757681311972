import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    associateRegister,
    associateConfCardAssociate,
    associateAssociate,
} from '../../../../redux/actions/associateAction';
import { confCardAssociate, associateSelector } from '../../../../redux/selectors';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';
import View from './view';

export default () => {
    const dispatch = useDispatch();
    const confCard = useSelector((state) => confCardAssociate(state));
    const associateRedux = useSelector((state) => associateSelector(state));

    const onChangeRegister = (e) => {
        dispatch(
            associateAssociate({
                ...associateRedux,
                [e.target.name]: e.target.value,
            })
        );
    };
    const onChangeSwitch = (e) => {
        dispatch(
            associateAssociate({
                ...associateRedux,
                enabled: e.target.checked,
            })
        );
    };

    const onCLickRegister = () => {
        dispatch(isLoadingAction(true));
        dispatch(associateRegister());
    };
    const onCLickLimpiar = () => {
        dispatch(
            associateAssociate({
                name: '',
                type: '',
                dms: '',
                enabled: true,
                STORE: '',
            })
        );
        dispatch(
            associateConfCardAssociate({
                cardTitle: 'Create Associate',
                buttonCreate: 'Create',
                textFildDms: false,
            })
        );
    };

    return (
        <View
            onChangeRegister={onChangeRegister}
            onCLickRegister={onCLickRegister}
            register={associateRedux}
            onCLickLimpiar={onCLickLimpiar}
            editAssociate={confCard}
            onChangeSwitch={onChangeSwitch}
        />
    );
};
