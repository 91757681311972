import React, { useState, useEffect, useCallback } from 'react';
import View from './view';
import { listResponseSelector, isLoading } from '../../redux/selectors';
import { isLoadingAction, snackBarAction } from '../../redux/actions/utilitiesAction';
import { listTermAction } from '../../redux/actions/termAction';
import { associateListByType } from '../../redux/actions/associateAction';
import { useSelector, useDispatch } from 'react-redux';

import {
    getTMListAction,
    addMemberToTMAction,
    deleteMemberOfTMAction,
} from '../../redux/actions/TMAction';

export default () => {
    const associateList = useSelector((state) => listResponseSelector(state, 'associateReducer'));
    const tmListResponse = useSelector((state) => listResponseSelector(state, 'TMReducer'));
    const listTerm = useSelector((state) => listResponseSelector(state, 'termReducer'));
    const isLoadingStatus = useSelector((state) => isLoading(state));

    const dispatch = useDispatch();
    const [tmData, setTMData] = useState();
    const [termName, setTermName] = useState();
    const [tmMembersList, setTMMembersList] = useState([]);
    const [addAMemberToTM, setAddAMemberToTM] = useState(null);

    useEffect(() => {
        dispatch(listTermAction());
        dispatch(associateListByType());
    }, [listTerm.length]);

    useEffect(() => {
        const tmMembersWithNames = tmListResponse?.[0]?.ASSOCIATES?.team?.flatMap((associate) => {
            return associateList[0].filter(
                (seller) => seller.dms_number === associate.associate_id
            );
        });
        setTMMembersList(tmMembersWithNames);
    }, [tmListResponse]);

    const TMFilterData = {
        TM: {
            name: 'Team manager',
            options: associateList[2],
            label: 'Team Manager',
            onChange: useCallback((event, value) => {
                setTMData(value);
            }, []),
        },
        Associate: {
            name: 'Terms',
            options: listTerm,
            label: 'Terms Name',
            onChange: useCallback((event, value) => {
                setTermName(value);
            }, []),
        },
    };

    const addTMMemberData = {
        Associate: {
            name: 'Associate',
            options: associateList[0],
            label: 'Associates',
            onChange: useCallback(
                (event, value) => {
                    setAddAMemberToTM(value);
                },
                [addAMemberToTM]
            ),
        },
    };

    const showTMListHandler = useCallback(() => {
        if (!(termName?.NAME && tmData?.dms_number))
            return dispatch(
                snackBarAction({
                    open: true,
                    type: 'error',
                    msg: 'Please select the  input fields',
                })
            );

        dispatch(getTMListAction(termName?.NAME, tmData?.dms_number));
        dispatch(isLoadingAction(true));
    }, [tmData, termName]);

    const submitMemberToTMHandler = useCallback(() => {
        if (!(termName?.NAME && tmData?.dms_number && !addAMemberToTM?.length))
            return dispatch(
                snackBarAction({
                    open: true,
                    type: 'error',
                    msg: 'Please select the above input fields',
                })
            );
        dispatch(addMemberToTMAction(termName?.NAME, tmData?.dms_number, addAMemberToTM));
        dispatch(isLoadingAction(true));
    }, [addAMemberToTM]);

    const deleteMemberOfTMHandler = useCallback(
        (associate_id) => {
            if (!(termName?.NAME && tmData?.dms_number))
                return dispatch(
                    snackBarAction({
                        open: true,
                        type: 'error',
                        msg: 'Please select the above input fields',
                    })
                );
            dispatch(deleteMemberOfTMAction(termName?.NAME, tmData?.dms_number, associate_id));
            dispatch(isLoadingAction(true));
        },
        [tmData, termName]
    );

    return (
        <View
            TMFilterData={TMFilterData}
            showTMListHandler={showTMListHandler}
            isLoading={isLoadingStatus}
            tmMembersList={tmMembersList}
            addTMMemberData={addTMMemberData}
            submitMemberToTMHandler={submitMemberToTMHandler}
            deleteMemberOfTMHandler={deleteMemberOfTMHandler}
        />
    );
};
