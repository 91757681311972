import { put } from 'redux-saga/effects';
import { getLocalStorage } from '../../consts/localStorage';
import { SIGN_IN_COMPLETE } from '../../consts/actionTypes';
import { isLoadingAction } from '../actions/utilitiesAction';

export default function* loadDataOnPageLoad() {
    yield put(isLoadingAction(true));

    const access_token = yield getLocalStorage();
    const results = { data: { access_token } };
    yield put({ type: SIGN_IN_COMPLETE, results });
    yield put(isLoadingAction(false));

}
