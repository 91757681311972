import React from 'react';
import {
    Paper,
    Grid,
    IconButton,
    Dialog,
    Fab,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import { months, years } from '../../../../consts/date';
import SearchIcon from '@material-ui/icons/Search';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { SelectDropDown } from '../../../../components';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ReactToPrint from 'react-to-print';
import { PreViewPrint } from '../../../../components';

export default (props) => {
    const {
        dataSearchDeals,
        onChangeDataSearch,
        onCLickSearch,
        refDivPrint,
        onOpenDialogPrint,
        openDilogPrint,
        onCloseDialogPrint,
        activePrint,
        listAssociate,
        typeAssociate,
        handleChangeRadio,
        activeSearch,
        onChangeDataSearchAutocomplete,
        clearAutoComplete,
        dmsData,
        dataTableDeals,
        bonus,
        summary,
        payroll,
        dataTableAdjustment,
        dataTableDealsNew,
        dataTableDealsUsed,
        dataTableDeals2,
        dataTableAdjustmentTemp,
        isPrintLessDetails,
        printLessDetailsHandler,
        onStoreChange,
        selectedStore,
        options,
    } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    <RadioGroup
                        aria-label='type'
                        name='Type'
                        value={typeAssociate}
                        onChange={handleChangeRadio}
                    >
                        <FormControlLabel value='0' control={<Radio />} label='Seller' />
                        <FormControlLabel value='1' control={<Radio />} label='FIM' />
                        <FormControlLabel value='2' control={<Radio />} label='TM' />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Autocomplete
                        id='store_washout'
                        options={options}
                        size='small'
                        getOptionLabel={(option) => option.title}
                        value={selectedStore}
                        onChange={onStoreChange}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField {...params} label='store' variant='outlined' />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Autocomplete
                        id='autocomplete_asso_washout'
                        key={clearAutoComplete}
                        options={listAssociate?.[parseInt(typeAssociate)]}
                        size='small'
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => onChangeDataSearchAutocomplete(value)}
                        renderInput={(params) => (
                            <TextField {...params} label='associate' variant='outlined' />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <div className={classes.spacing}>
                        <SelectDropDown
                            labelName={'Month'}
                            nameSelect={'month'}
                            valueProp={dataSearchDeals.month}
                            onChangeProp={onChangeDataSearch}
                            objectList={months}
                            keyValue={'code'}
                            keyLabel={'name'}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <div className={classes.spacing}>
                        <SelectDropDown
                            labelName={'Year'}
                            nameSelect={'year'}
                            valueProp={dataSearchDeals.year}
                            onChangeProp={onChangeDataSearch}
                            objectList={years}
                            keyValue={'code'}
                            keyLabel={'name'}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    <div className={classes.spacing}>
                        <IconButton
                            color='primary'
                            variant='contained'
                            onClick={onCLickSearch}
                            disabled={activeSearch}
                        >
                            <SearchIcon className={classes.Icons} />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} container justify="flex-end" alignItems="center" direction="column" spacing={2} columns={6}>
                    <div className={classes.printiconbutton}>
                        <IconButton
                            color='primary'
                            variant='contained'
                            onClick={printLessDetailsHandler}
                            disabled={activePrint}
                        >
                            <Typography variant='h6' style={{ marginRight: '10px' }}>
                                Commission Sheet
                            </Typography>
                            <PrintOutlinedIcon className={classes.Icons} />
                        </IconButton>
                        <IconButton
                            color='primary'
                            variant='contained'
                            onClick={onOpenDialogPrint}
                            disabled={activePrint}
                        >
                            <Typography variant='h6' style={{ marginRight: '10px' }}>
                                Old Commission Sheet
                            </Typography>
                            <PrintOutlinedIcon className={classes.Icons} />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Dialog fullScreen open={openDilogPrint} onClose={onCloseDialogPrint}>
                <div>
                    <Fab className={classes.fabClose} onClick={onCloseDialogPrint}>
                        <CancelOutlinedIcon fontSize='large' />
                    </Fab>
                    <ReactToPrint
                        trigger={() => (
                            <Fab className={classes.fabPrint}>
                                <PrintOutlinedIcon fontSize='large' />
                            </Fab>
                        )}
                        content={() => refDivPrint.current}
                        onAfterPrint={() => {
                            onCloseDialogPrint();
                        }}
                    />

                    <div ref={refDivPrint}>
                        <PreViewPrint
                            isPrintLessDetails={isPrintLessDetails}
                            dmsData={dmsData}
                            bonus={bonus}
                            summary={summary}
                            payroll={payroll}
                            dataTableDeals={dataTableDeals}
                            dataTableDeals2={typeAssociate == '0' ? null : dataTableDeals2}
                            dataTableAdjustment={dataTableAdjustmentTemp}
                            dataTableDealsNew={typeAssociate == '0' ? dataTableDealsNew : null}
                            dataTableDealsUsed={typeAssociate == '0' ? dataTableDealsUsed : null}
                            dateReport={`${dataSearchDeals.month}${dataSearchDeals.year}`}
                            isTM={typeAssociate === '2'}
                            dataSearchDeals={dataSearchDeals}
                        />
                    </div>
                </div>
            </Dialog>
        </Paper>
    );
};
