import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flex: 1,
    },
    container: {
        maxHeight: 330,
    },
    cell: {
        padding: 0,
        paddingRight: 1,
        flexDirection: 'row',
    },
}));
