import React from 'react';
import {
    Paper,
    Grid,
    Button,
    TextField,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { useStyles } from './styles';
import { months, years } from '../../consts/date';
import { SelectDropDown } from '../../components';
import Edit from '@material-ui/icons/Edit';
import { Save, Cancel } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

const View = (props) => {
    const {
        isEditable,
        onRowEdit,
        onEdit,
        data,
        handleDateChange,
        selectedMonth,
        selectedYear,
        isLoadingResponse,
        onDiscardChanges,
        selectedSP,
        onSaveChanges,
        onSearch,
    } = props;
    const classes = useStyles();
    return (
        <>
            {!isLoadingResponse ? (
                <Paper className={classes.root}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.spacing}>
                                <SelectDropDown
                                    labelName={'Month'}
                                    nameSelect={'month'}
                                    valueProp={selectedMonth}
                                    onChangeProp={(e) => handleDateChange(e.target)}
                                    objectList={months}
                                    keyValue={'code'}
                                    keyLabel={'name'}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.spacing}>
                                <SelectDropDown
                                    labelName={'Year'}
                                    nameSelect={'year'}
                                    valueProp={selectedYear}
                                    onChangeProp={(e) => handleDateChange(e.target)}
                                    objectList={years}
                                    keyValue={'code'}
                                    keyLabel={'name'}
                                />
                            </div>
                        </Grid>
                        <TextField
                            label='Search'
                            name='searchfield'
                            size='small'
                            onChange={onSearch}
                            variant='outlined'
                        />
                        <Paper className={classes.btnContainer}>
                            {!isEditable ? (
                                <Button
                                    color='primary'
                                    variant='contained'
                                    startIcon={<Edit />}
                                    onClick={onEdit.bind(null, true)}
                                >
                                    Edit
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        startIcon={<Save />}
                                        onClick={onSaveChanges}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        color='inherit'
                                        variant='contained'
                                        startIcon={<Cancel />}
                                        onClick={onDiscardChanges}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </Paper>
                    </Grid>
                    <Paper className={classes.dealContainer}>
                        <Typography style={{ marginBottom: '1rem' }} variant='h3' gutterBottom>
                            Transfer Deals
                        </Typography>
                        <div style={{ width: '100%', overflowX: 'auto' }}>
                            <Table style={{ minWidth: '800px' }} size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '40%' }}>Deal</TableCell>
                                        <TableCell style={{ width: '20%' }}>SP1</TableCell>
                                        <TableCell style={{ width: '20%' }}>SP2</TableCell>
                                        <TableCell style={{ width: '20%' }}>FIM</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.deal
                                        .filter((deal) => !deal?.TRANSFERRED)
                                        .map((deal, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell sx={{ backgroundColor: 'red' }}>
                                                        <TextField
                                                            fullWidth
                                                            label='Deal'
                                                            name='Deal'
                                                            size='small'
                                                            value={`${deal?.FIWI}`}
                                                            variant='outlined'
                                                            disabled={true}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditable ? (
                                                            <SelectDropDown
                                                                labelName={'SP1'}
                                                                nameSelect={'SP1'}
                                                                valueProp={
                                                                    deal?.TRANSFERRED
                                                                        ? deal?.SANO1
                                                                        : undefined
                                                                }
                                                                onChangeProp={(e) =>
                                                                    onRowEdit({
                                                                        SANO1: e.target.value,
                                                                        _id: deal?._id,
                                                                        FIWI: deal?.FIWI,
                                                                    })
                                                                }
                                                                objectList={data?.sp}
                                                                keyValue={'dms_number'}
                                                                keyLabel={'name'}
                                                                getLabelOption={(obj) =>
                                                                    `${obj.dms_number} - ${obj.name}`
                                                                }
                                                                disableValue={
                                                                    !isEditable || deal?.TRANSFERRED
                                                                }
                                                            />
                                                        ) : (
                                                            <TextField
                                                                fullWidth
                                                                label='SP1'
                                                                name='Deal'
                                                                size='small'
                                                                value={`SP1`}
                                                                variant='outlined'
                                                                disabled={true}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditable ? (
                                                            <SelectDropDown
                                                                labelName={'SP2'}
                                                                nameSelect={'SP2'}
                                                                valueProp={
                                                                    deal?.TRANSFERRED
                                                                        ? deal?.SANO2 || ' '
                                                                        : undefined
                                                                }
                                                                onChangeProp={(e) =>
                                                                    onRowEdit({
                                                                        SANO2: e.target.value,
                                                                        _id: deal?._id,
                                                                        FIWI: deal?.FIWI,
                                                                    })
                                                                }
                                                                objectList={data?.sp}
                                                                keyValue={'dms_number'}
                                                                keyLabel={'name'}
                                                                getLabelOption={(obj) =>
                                                                    `${obj.dms_number} - ${obj.name}`
                                                                }
                                                                disableValue={
                                                                    !isEditable ||
                                                                    deal?.TRANSFERRED ||
                                                                    !selectedSP[deal?.SANO1]
                                                                }
                                                            />
                                                        ) : (
                                                            <TextField
                                                                fullWidth
                                                                label='Deal'
                                                                name='Deal'
                                                                size='small'
                                                                value={`SP2`}
                                                                variant='outlined'
                                                                disabled={true}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditable ? (
                                                            <SelectDropDown
                                                                labelName={'FIM'}
                                                                nameSelect={'FIM'}
                                                                valueProp={
                                                                    deal?.TRANSFERRED
                                                                        ? deal?.FIMA
                                                                        : undefined
                                                                }
                                                                onChangeProp={(e) =>
                                                                    onRowEdit({
                                                                        FIMA: e.target.value,
                                                                        _id: deal?._id,
                                                                        FIWI: deal?.FIWI,
                                                                    })
                                                                }
                                                                objectList={data?.fim}
                                                                keyValue={'dms_number'}
                                                                keyLabel={'name'}
                                                                getLabelOption={(obj) =>
                                                                    `${obj.dms_number} - ${obj.name}`
                                                                }
                                                                disableValue={
                                                                    !isEditable || deal?.TRANSFERRED
                                                                }
                                                            />
                                                        ) : (
                                                            <TextField
                                                                fullWidth
                                                                label='FIM'
                                                                name='Deal'
                                                                size='small'
                                                                value={`FIM`}
                                                                variant='outlined'
                                                                disabled={true}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                    <Paper className={classes.dealContainer}>
                        <Typography style={{ marginBottom: '1rem' }} variant='h3' gutterBottom>
                            Deals Transferred
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid style={{ overflow: 'auto' }} item sm={12} lg={6}>
                                <Typography
                                    style={{ marginBottom: '1rem', textAlign: 'center' }}
                                    variant='h4'
                                    gutterBottom
                                >
                                    Previous Information
                                </Typography>
                                <div>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '10%' }}>Deal</TableCell>
                                                <TableCell>SP1</TableCell>
                                                <TableCell>SP2</TableCell>
                                                <TableCell>FIM</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.deal
                                                .filter((deal) => deal?.TRANSFERRED)
                                                .map((deal, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell
                                                                sx={{ backgroundColor: 'red' }}
                                                            >
                                                                <Typography
                                                                    className={classes.font14}
                                                                    variant='h5'
                                                                >
                                                                    {deal?.FIWI}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    className={classes.font14}
                                                                    variant='h5'
                                                                >
                                                                    {`${
                                                                        deal?.SANO1_TRANSFERRED ||
                                                                        ''
                                                                    } - ${
                                                                        deal?.SANO1NA_TRANSFERRED ||
                                                                        ''
                                                                    }`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    className={classes.font14}
                                                                    variant='h5'
                                                                >
                                                                    {`${
                                                                        deal?.SANO2_TRANSFERRED ||
                                                                        ''
                                                                    } - ${
                                                                        deal?.SANO2NA_TRANSFERRED ||
                                                                        ''
                                                                    }`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    className={classes.font14}
                                                                    variant='h5'
                                                                >
                                                                    {`${
                                                                        deal?.FIMA_TRANSFERRED || ''
                                                                    } - ${
                                                                        deal?.FIMANA_TRANSFERRED ||
                                                                        ''
                                                                    }`}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid style={{ overflow: 'auto' }} item sm={12} lg={6}>
                                <Typography
                                    style={{ marginBottom: '1rem', textAlign: 'center' }}
                                    variant='h4'
                                    gutterBottom
                                >
                                    New Information
                                </Typography>
                                <div style={{ overflow: 'auto' }}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell style={{ width: '10%' }}>Deal</TableCell> */}
                                                <TableCell>SP1</TableCell>
                                                <TableCell>SP2</TableCell>
                                                <TableCell>FIM</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.deal
                                                .filter((deal) => deal?.TRANSFERRED)
                                                .map((deal, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {/* <TableCell sx={{ backgroundColor: 'red' }}>
                                                        <Typography
                                                            className={classes.font14}
                                                            variant="h5"
                                                        >
                                                            {deal?.FIWI}
                                                        </Typography>
                                                    </TableCell> */}
                                                            <TableCell>
                                                                {isEditable ? (
                                                                    <SelectDropDown
                                                                        labelName={'SP1'}
                                                                        nameSelect={'SP1'}
                                                                        onChangeProp={(e) =>
                                                                            onRowEdit({
                                                                                SANO1: e.target
                                                                                    .value,
                                                                                _id: deal?._id,
                                                                                FIWI: deal?.FIWI,
                                                                            })
                                                                        }
                                                                        objectList={data?.sp}
                                                                        keyValue={'dms_number'}
                                                                        keyLabel={'name'}
                                                                        getLabelOption={(obj) =>
                                                                            `${obj.dms_number} - ${obj.name}`
                                                                        }
                                                                        disableValue={!isEditable}
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        label='SP1'
                                                                        name='Deal'
                                                                        size='small'
                                                                        value={`SP1`}
                                                                        variant='outlined'
                                                                        disabled={true}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {isEditable ? (
                                                                    <SelectDropDown
                                                                        labelName={'SP2'}
                                                                        nameSelect={'SP2'}
                                                                        onChangeProp={(e) =>
                                                                            onRowEdit({
                                                                                SANO2: e.target
                                                                                    .value,
                                                                                _id: deal?._id,
                                                                                FIWI: deal?.FIWI,
                                                                            })
                                                                        }
                                                                        objectList={data?.sp}
                                                                        keyValue={'dms_number'}
                                                                        keyLabel={'name'}
                                                                        getLabelOption={(obj) =>
                                                                            `${obj.dms_number} - ${obj.name}`
                                                                        }
                                                                        disableValue={
                                                                            !isEditable ||
                                                                            !selectedSP[deal?.SANO1]
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        label='SP2'
                                                                        name='Deal'
                                                                        size='small'
                                                                        value={`SP2`}
                                                                        variant='outlined'
                                                                        disabled={true}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {isEditable ? (
                                                                    <SelectDropDown
                                                                        labelName={'FIM'}
                                                                        nameSelect={'FIM'}
                                                                        onChangeProp={(e) =>
                                                                            onRowEdit({
                                                                                FIMA: e.target
                                                                                    .value,
                                                                                _id: deal?._id,
                                                                                FIWI: deal?.FIWI,
                                                                            })
                                                                        }
                                                                        objectList={data?.fim}
                                                                        keyValue={'dms_number'}
                                                                        keyLabel={'name'}
                                                                        getLabelOption={(obj) =>
                                                                            `${obj.dms_number} - ${obj.name}`
                                                                        }
                                                                        disableValue={!isEditable}
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        label='FIM'
                                                                        name='Deal'
                                                                        size='small'
                                                                        value={`FIM`}
                                                                        variant='outlined'
                                                                        disabled={true}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Paper>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                    }}
                >
                    <CircularProgress />
                </div>
            )}
        </>
    );
};

export default View;
