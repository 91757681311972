import React from 'react';
import { useStyles } from './styles';
import { Grid, Button, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { BackdropProgress, SnackbarAlert } from '../../components';

export default (props) => {
    const {
        login,
        handleSignIn,
        onChangeLogin,
        isLoadingResponse,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
        onEnter,
    } = props;

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SnackbarAlert />
            <BackdropProgress isLoadingResponse={isLoadingResponse} size={75} />

            <Grid className={classes.grid} container item lg={6}>
                <Grid className={classes.quoteContainer} item lg={6}>
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <img src='/images/logos/logoUpturn.png' alt='logoUpTurn' />
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={6} xs={12}>
                    <div className={classes.content} onKeyDown={onEnter}>
                        <div className={classes.contentBody}>
                            <div className={classes.form}>
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label='User Name'
                                    name='username'
                                    value={login.username}
                                    onChange={onChangeLogin}
                                    type='text'
                                    variant='outlined'
                                    size='small'
                                />
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    label='Password'
                                    name='password'
                                    value={login.password}
                                    onChange={onChangeLogin}
                                    size='small'
                                    type={showPassword ? 'text' : 'password'}
                                    variant='outlined'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={'end'}>
                                                <IconButton
                                                    aria-label={'toggle password visibility'}
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge={'end'}
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    className={classes.signInButton}
                                    color='secondary'
                                    fullWidth
                                    size='large'
                                    onClick={handleSignIn}
                                    variant='contained'
                                    disabled={!login.password || !login.username}
                                >
                                    Log In
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
