import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import { CardDetail, CardTableMultiRow } from './CardDetail';

const View = (props) => {
    const { bonus, summary, payroll } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                <CardTableMultiRow title={'Summary'} object={summary} />
                <div style={{ height: 15 }} />
                <CardDetail titleCard={'Bonus'}>
                    <TableRow>
                        <TableCell size='small' padding='none' align='left'>
                            {'Christmas bonus'}
                        </TableCell>
                        <TableCell size='small' padding='none' align='right'>
                        {`$${bonus?.toFixed(2)}`}
                        </TableCell>
                    </TableRow>
                </CardDetail>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                <CardTableMultiRow title={'Payroll'} object={payroll} />
            </Grid>
        </Grid>
    );
};

View.Props = {
    bonus: PropTypes.object.isRequired,
    summary: PropTypes.array.isRequired,
    payroll: PropTypes.array.isRequired,
};
export default View;
