import { get } from 'lodash';
import { PAYPLAN_LIST_COMPLETE, PAYPLAN_LIST_ERROR } from '../../consts/actionTypes';

const initialState = {
    listResponse: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYPLAN_LIST_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'results.data'),
            };

        default:
            return { ...state };
    }
}
