import React, { useEffect } from 'react';

import { months } from '../../consts/date';
import { useDispatch, useSelector } from 'react-redux';

import { isLoadingAction, snackBarAction } from '../../redux/actions/utilitiesAction';
import { listResponseSelector, token, isLoading } from '../../redux/selectors';

import View from './view';
import {
    createDataAction,
    deleteDataAction,
    listDataAction,
    upDateDataAction,
} from '../../redux/actions/payPlanAction';

export default () => {
    const dispatch = useDispatch();
    const listResponse = useSelector((state) => listResponseSelector(state, 'payPlanReducerV2'));
    const responseToken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));

    useEffect(() => {
        if (!listResponse && responseToken) {
            dispatch(isLoadingAction(true));
            dispatch(listDataAction());
        }
    }, [listResponse, responseToken, dispatch]);

    const onRowAdd = (obj, newData, data) => {
        let endRow = { ...data };
        delete endRow.ADJ_ID;
        delete endRow.tableData;
        delete endRow._id;

        dispatch(isLoadingAction(true));
        dispatch(createDataAction(newData, obj));
    };
    const onRowDelete = (obj, oldData) => {
        dispatch(isLoadingAction(true));
        dispatch(deleteDataAction(oldData._id, obj));
    };
    const onRowEdit = (obj, newData, oldData) => {
        let data = { ...newData };
        let firstchar = data.POSTAMTEXT.charAt(0);
        if (firstchar === '$') {
            data = {
                ...data,
                POSTAMTEXT: data.POSTAMTEXT.slice(1),
            };
        }
        dispatch(isLoadingAction(true));
        dispatch(upDateDataAction(newData._id, data));
    };

    return (
        <View
            isLoadingResponse={isLoadingResponse}
            onRowAdd={onRowAdd}
            onRowDelete={onRowDelete}
            onRowEdit={onRowEdit}
        />
    );
};
