import {
    REPORT_TOTALS_START,
    REPORT_SALES_START,
    CLEAN_REPORTS,
    FETCH_REPORT_PAYOUT_START,
    FETCH_REPORT_PAYOUT_PER_STORE_START,
    FETCH_REPORT_TMS_START,
    FETCH_REPORT_TMS_SALES_START,
} from '../../consts/actionTypes';

export const reportTotalsAction = (data) => ({
    type: REPORT_TOTALS_START,
    data,
});
export const reportSalesAction = (data) => ({
    type: REPORT_SALES_START,
    data,
});
export const cleanReportsAction = () => ({
    type: CLEAN_REPORTS,
});

export const fetchReportPaypoutAction = (data) => ({
    type: FETCH_REPORT_PAYOUT_START,
    data,
});

export const fetchReportPaypoutPerStoreAction = (data) => ({
    type: FETCH_REPORT_PAYOUT_PER_STORE_START,
    data,
});

export const fetchReportTMSAction = (data) => ({
    type: FETCH_REPORT_TMS_START,
    data,
});

export const fetchReportTMSSalesAction = (data) => ({
    type: FETCH_REPORT_TMS_SALES_START,
    data,
});
