import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { SnackbarAlert } from '../../components';
import { Sidebar, Topbar, Footer } from './components';
import { useStyles } from './styles';

const Main = (props) => {
    const { children } = props;
    const [fixedSidebar, setFixedSidebar] = useState(true);
    const [visibleMenuIcon, setVisibleMenuIcon] = useState(true);
    const classes = useStyles();
    const theme = useTheme();
    const size = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    const isDesktop = fixedSidebar ? size : false;

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    const handleFixedSidebar = (e) => {
        setFixedSidebar(e.target.checked);
    };
    useEffect(() => {
        setOpenSidebar(false);
        if (size) {
            if (fixedSidebar) {
                return setVisibleMenuIcon(false);
            }
        }
        setVisibleMenuIcon(true);
    }, [fixedSidebar, size]);

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop,
            })}
        >
            <SnackbarAlert />
            <Topbar onSidebarOpen={handleSidebarOpen} visibleMenuIcon={visibleMenuIcon} />
            <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
                fixedSidebar={fixedSidebar}
                handleFixedSidebar={handleFixedSidebar}
                size={size}
            />
            <main className={classes.content}>
                {children}
                <Footer />
            </main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;
