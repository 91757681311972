import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    SEARCH_DEAL_START,
    SEARCH_DEAL_COMPLETE,
    SEARCH_SUBDEAL_BY_ASSOCIATE_START,
    SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE,
    SEARCH_DEALS_BY_MONTH_START,
    SEARCH_DEALS_BY_MONTH_COMPLETE,
    EDIT_DEAL_START,
} from '../../consts/actionTypes';

import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { DEAL, SUBDEAL_ASSOC_DEAL, DEALS, MONTH, DEAL_API } from '../../consts/urls';
import { token, dealSelector } from '../selectors';

export function* searchDeal({ payload }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${DEAL}/${payload.deal}`, null, responseToken, 'GET');

        yield put({ type: SEARCH_DEAL_COMPLETE, results });

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        console.log('error', error);

        if (error.response) {
            const errorResponse = error.response;
            console.log(errorResponse);

            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        } else {
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'number not found: ' + payload.deal + '',
                    type: 'error',
                })
            );
        }
    }
    yield put(isLoadingAction(false));
}
export function* searchDealAssociate({ payload }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${SUBDEAL_ASSOC_DEAL}/${payload.associate_num}/${payload.deal}`,
            null,
            responseToken,
            'GET'
        );

        yield put({ type: SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE, results });

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        console.log('error', error);
        if (error.response) {
            const errorResponse = error.response;
            console.log(errorResponse);

            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        } else {
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'code associate or deal number is not found: ',
                    type: 'error',
                })
            );
        }
    }
    yield put(isLoadingAction(false));
}
export function* searchDealsMonth({ payload }) {
    try {
        const date = `${payload.month}${payload.year}`;
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${DEAL}/${MONTH}/${date}`, null, responseToken, 'GET');

        yield put({ type: SEARCH_DEALS_BY_MONTH_COMPLETE, results });

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        /* yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse })
    yield put(snackBarAction(
        {
            open: true,
            msg: 'error Load List: ' + errorResponse.data.message,
            type: 'error'
        }
    ));*/
    }
    yield put(isLoadingAction(false));
}
export function* editDeal({ payload }) {
    try {
        const dealState = yield select(dealSelector);

        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${DEAL_API}/${payload.deal}`,
            dealState,
            responseToken,
            'PUT'
        );
        console.log(results);
        // yield put({ type: SEARCH_DEALS_BY_MONTH_COMPLETE, results })

        yield put(
            snackBarAction({
                open: true,
                msg: 'updated successfully',
                type: 'success',
            })
        );
    } catch (error) {
        //const errorResponse = error.response;
        console.log(error);
        //yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse })
        yield put(
            snackBarAction({
                open: true,
                msg: 'error updated ',
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* search() {
    yield takeLatest(SEARCH_DEAL_START, searchDeal);
    yield takeLatest(SEARCH_SUBDEAL_BY_ASSOCIATE_START, searchDealAssociate);
    yield takeLatest(SEARCH_DEALS_BY_MONTH_START, searchDealsMonth);
    yield takeLatest(EDIT_DEAL_START, editDeal);
}
