import { GET_FIM_START,GET_SP_START,GET_DEAL_START,UPDATE_DEAL,DISCARD_DEAL_CHANGES,EDIT_TRANSFER_DEAL_START } from "../../consts/actionTypes";

export const getFIMListAction = () => ({
    type: GET_FIM_START,
});

export const getSPListAction = () => ({
    type: GET_SP_START,
});

export const getDealListAction = (date) => ({
    type: GET_DEAL_START,
    payload: date
});

export const updateTransferDealAction = (data) => ({
    type: EDIT_TRANSFER_DEAL_START,
    payload: data
});

export const updateDealAction = (data) => ({
    type: UPDATE_DEAL,
    payload: data
});

export const discardDealChangesAction = () => ({
    type: DISCARD_DEAL_CHANGES,
});

