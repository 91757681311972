import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    paper: {
        margin: theme.spacing(1),
    },
}));
