import { all } from 'redux-saga/effects';
import login from './loginSaga';
import associate from './associateSaga';
import subDeals from './washoutSaga';
import adjustment from './adjustmentSaga';
import extraction from './extractionSaga';
import subdealApi from './subdealApiSaga';
import search from './searchSaga';
import payPlan from './payPlanSaga';
import term from './termSaga';
import reports from './reportSaga';
import loadData from './localSaga';
import TMwatch from './TMsaga';
import transferDealWatch from "./transferDealSaga"

export default function* rootSaga() {
    yield all([
        login(),
        associate(),
        subDeals(),
        adjustment(),
        extraction(),
        subdealApi(),
        search(),
        payPlan(),
        term(),
        reports(),
        loadData(),
        TMwatch(),
        transferDealWatch(),
    ]);
}
