import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 20,
    },
    cardAdd: {
        display: 'flex',
        flexDirection: 'row',
        height: 60,
        padding: 10,
    },
}));
