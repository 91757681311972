import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dialogMessageAction } from '../../redux/actions/utilitiesAction';
import { dialogMessage } from '../../redux/selectors';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default () => {
    const dispatch = useDispatch();
    const dialogMessageData = useSelector((state) => dialogMessage(state));
    const handleClose = () => {
        dispatch(
            dialogMessageAction({
                ...dialogMessageData,
                open: false,
            })
        );
    };

    return (
        <Dialog
            open={dialogMessageData.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle>{dialogMessageData.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogMessageData.text}</DialogContentText>
            </DialogContent>
            <DialogActions>{dialogMessageData.children}</DialogActions>
        </Dialog>
    );
};
