import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    TextField,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { DeleteOutline, Check, Edit, Clear, Add } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SelectDropDown } from '../../../../components';

const Index = (props) => {
    const {
        obj,
        isDisable,
        posicionArray,
        handleChange,
        listPayPlan,
        arrayAllAssociate,
        onDeleteAssociateCard,
        add,
        newObjInArrayAssociate,
        onClean,
        listTerm,
        handlePositiveClick,
    } = props;

    const [newAssoPayPlan, setNewAssoPayPlan] = useState({
        associate: '',
        payplan: '',
    });
    const [IsDisableCheck, setIsDisableCheck] = useState(true);
    const [clenAutoComplete, setClenAutoComplete] = useState(true);
    const [showAddTable, setShowAddTable] = useState(false);

    useEffect(() => {
        if (newAssoPayPlan.associate.length > 0 && newAssoPayPlan.payplan.length > 0) {
            setIsDisableCheck(false);
        } else {
            setIsDisableCheck(true);
        }
    }, [newAssoPayPlan]);

    useEffect(() => {
        onCleanCard();
    }, [onClean]);

    const getEvent = (index, event) => {
        handleChange(index, event);
    };

    const value = (code) => {
        if (code.length > 0) {
            let arrayBase = arrayAllAssociate;
            let indexArray = arrayBase.findIndex((e) => {
                return e.code === code;
            });
            return arrayBase[indexArray];
        } else {
            return null;
        }
    };
    const getNewAssociate = (v, index) => {
        let e = {
            target: {
                name: 'associate',
                value: v ? v.code : value(obj.associate).code,
            },
        };
        handleChange(index, e);
    };
    const onAdd = () => {
        newObjInArrayAssociate(newAssoPayPlan);
        onCleanCard();
    };
    const onCleanCard = () => {
        setNewAssoPayPlan({
            associate: '',
            payplan: '',
        });
        setClenAutoComplete(!clenAutoComplete);
    };
    const onChangeAdd = (value) => {
        if (value) {
            setNewAssoPayPlan({ ...newAssoPayPlan, associate: value.code });
        } else {
            setNewAssoPayPlan({ ...newAssoPayPlan, associate: '' });
        }
    };

    return (
        <>
            {!add ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Associate</TableCell>
                            <TableCell>Payplan</TableCell>
                            <TableCell style={{ position: 'relative' }} align='center'>
                                Actions
                                {showAddTable ? (
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: '1rem',
                                            top: '50%',
                                            translate: '0 -50%',
                                        }}
                                        onClick={() => setShowAddTable(false)}
                                    >
                                        <Clear style={{ fontSize: 18 }} />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        style={{
                                            position: 'absolute',
                                            right: '1rem',
                                            top: '50%',
                                            translate: '0 -50%',
                                        }}
                                        onClick={() => setShowAddTable(true)}
                                    >
                                        <Add style={{ fontSize: 18 }} />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showAddTable && props.children}

                        {listTerm.ASSOCIATES.map((obj, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Autocomplete
                                        id='combo-box-demo-associate-term'
                                        size='small'
                                        disabled={isDisable}
                                        key={clenAutoComplete}
                                        value={
                                            add
                                                ? value(newAssoPayPlan.associate)
                                                : value(obj.associate)
                                        }
                                        options={arrayAllAssociate}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {
                                            add
                                                ? onChangeAdd(value)
                                                : getNewAssociate(value, index);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='associate'
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <SelectDropDown
                                        labelName={'Pay Plan'}
                                        nameSelect={'payplan'}
                                        valueProp={add ? newAssoPayPlan.payplan : obj.payplan}
                                        onChangeProp={
                                            add
                                                ? (e) => {
                                                      setNewAssoPayPlan({
                                                          ...newAssoPayPlan,
                                                          payplan: e.target.value,
                                                      });
                                                  }
                                                : (e) => {
                                                      getEvent(index, e);
                                                  }
                                        }
                                        objectList={listPayPlan}
                                        keyValue={'NAME'}
                                        keyLabel={'NAME'}
                                        disableValue={isDisable}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton
                                        disabled={add ? IsDisableCheck : isDisable}
                                        onClick={() => {
                                            add ? onAdd() : onDeleteAssociateCard(obj.associate);
                                        }}
                                    >
                                        {add ? (
                                            <Check style={{ fontSize: 18 }} />
                                        ) : (
                                            <DeleteOutline style={{ fontSize: 18 }} />
                                        )}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <TableRow>
                    <TableCell>
                        <Autocomplete
                            id='combo-box-demo-associate-term'
                            size='small'
                            disabled={isDisable}
                            key={clenAutoComplete}
                            value={add ? value(newAssoPayPlan.associate) : value(obj.associate)}
                            options={arrayAllAssociate}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                                add ? onChangeAdd(value) : getNewAssociate(value);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label='associate' variant='outlined' />
                            )}
                        />
                    </TableCell>
                    <TableCell>
                        <SelectDropDown
                            labelName={'Pay Plan'}
                            nameSelect={'payplan'}
                            valueProp={add ? newAssoPayPlan.payplan : obj.payplan}
                            onChangeProp={
                                add
                                    ? (e) => {
                                          setNewAssoPayPlan({
                                              ...newAssoPayPlan,
                                              payplan: e.target.value,
                                          });
                                      }
                                    : getEvent
                            }
                            objectList={listPayPlan}
                            keyValue={'NAME'}
                            keyLabel={'NAME'}
                            disableValue={isDisable}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        <IconButton
                            disabled={add ? IsDisableCheck : isDisable}
                            onClick={() => {
                                add ? onAdd() : onDeleteAssociateCard(obj.associate);
                            }}
                        >
                            {add ? (
                                <Check style={{ fontSize: 18 }} />
                            ) : (
                                <DeleteOutline style={{ fontSize: 18 }} />
                            )}
                        </IconButton>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

Index.propTypes = {
    isDisable: PropTypes.bool.isRequired,
    listPayPlan: PropTypes.array.isRequired,
    arrayAllAssociate: PropTypes.array.isRequired,
    obj: PropTypes.object,
    posicionArray: PropTypes.number,
    handleChange: PropTypes.func,
    onDeleteAssociateCard: PropTypes.func,
    add: PropTypes.bool,
    newObjInArrayAssociate: PropTypes.func,
    onClean: PropTypes.bool.isRequired,
    listTerm: PropTypes.array,
    handleClick: PropTypes.object.isRequired,
};
Index.defaultProps = {
    add: false,
    obj: {},
    listTerm: { ASSOCIATES: [] },
    posicionArray: 0,
    handleChange: () => {
        console.log('handleChange');
    },
    onDeleteAssociateCard: () => {
        console.log('onDeleteAssociateCard');
    },
    newObjInArrayAssociate: () => {
        console.log('newObjInArrayAssociate');
    },
};

export default Index;
