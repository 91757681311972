import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    fabPrint: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 5,
        left: 'auto',
        position: 'fixed',
        zIndex: 1,
    },
}));
