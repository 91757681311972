import React from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { SimpleProgress } from '../';
import { tableIcons } from './icons';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

const View = (props) => {
    const {
        state,
        isLoadingResponse,
        initalRowNum,
        optionRowNum,
        isGrouping,
        rowEditables,
        actionActive,
        openDetail,
        openMessageConfirmDelete,
        expandInfo,
        handleDialogFullScreen,
        allPrint,
        isTM,
        // loading,
    } = props;

    const styleDataTable = () => {
        let columns = [];
        let obj = {};

        for (let row of state?.columns) {
            if (row.hasOwnProperty('type') && row.type === 'numeric') {
                obj = {
                    ...row,
                    headerStyle:{
                        textAlign: row?.position ? row?.position : 'center', 
                    },
                    cellStyle: {
                        padding: 0,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 5,
                        textAlign: row?.position ? row?.position : 'center',
                        flexDirection: 'row',
                        whiteSpace: 'pre',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '85%',
                    },
                };
            } else {
                obj = {
                    ...row,
                    headerStyle:{
                        textAlign: row?.position ? row?.position : 'center', 
                    },
                    cellStyle: {
                        padding: 0,
                        paddingTop: 10,
                        paddingBottom: 0,
                        paddingRight: 5,
                        textAlign: row?.position ? row?.position : 'center',
                        flexDirection: 'row',
                        whiteSpace: 'pre',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '85%',
                    },
                };
            }
            columns.push(obj);
        }
        return columns;
    };

    if (isLoadingResponse) {
        return <SimpleProgress />;
    }
    const columnActions = () => {
        let actionsColumns = [];
        if (expandInfo) {
            actionsColumns = [
                {
                    icon: DescriptionIcon,
                    tooltip: 'Detail',
                    hidden: actionActive,
                    onClick: (event, rowData) => handleDialogFullScreen(rowData),
                },
            ];
        } else {
            actionsColumns = [
                {
                    icon: tableIcons.Edit,
                    tooltip: 'Edit Subdeal',
                    hidden: actionActive,
                    onClick: (event, rowData) => openDetail(rowData),
                },
                {
                    icon: SettingsBackupRestoreIcon,
                    tooltip: 'Reversal Subdeal',
                    hidden: actionActive,
                    onClick: (event, rowData) => openMessageConfirmDelete(rowData, 'r'),
                },

                {
                    icon: tableIcons.Delete,
                    tooltip: 'Delete Subdeal',
                    hidden: actionActive,
                    onClick: (event, rowData) => openMessageConfirmDelete(rowData, 'd'),
                },
            ];
        }
        return actionsColumns;
    };
    return (
        <MaterialTable
            title={''}
            icons={tableIcons}
            columns={styleDataTable()}
            data={state.data}
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            //isLoading={loading}
            options={{
                addRowPosition: 'first',
                actionsCellStyle: { display: 'flex', justifyContent: 'center', paddingTop: 13 },
                headerStyle: {
                    padding: 0,
                    paddingRight: 0,
                    paddingLeft: 5,
                    textAlign: 'center',
                    flexDirection: 'row',
                    whiteSpace: 'pre',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '85%',
                },
                padding: 'dense',
                paginationType: 'stepped',
                pageSize: initalRowNum(),
                pageSizeOptions: optionRowNum(),
                grouping: isGrouping,
                exportButton: true,
                exportAllData: true,
            }}
            editable={rowEditables}
            actions={columnActions()}
        />
    );
};
View.propTypes = {
    state: PropTypes.object.isRequired,
    isLoadingResponse: PropTypes.bool.isRequired,
    initalRowNum: PropTypes.func.isRequired,
    optionRowNum: PropTypes.func.isRequired,
    isGrouping: PropTypes.bool.isRequired,
    rowEditables: PropTypes.any,
    actionActive: PropTypes.bool.isRequired,
    openDetail: PropTypes.func,
    openMessageConfirmDelete: PropTypes.func,
    expandInfo: PropTypes.bool,
    allPrint: PropTypes.func,
    // loading,,
};
View.defaultProps = {
    expandInfo: false,
    allPrint: () => console.log('allprint'),
};

export default View;
