import {
    SEARCH_DEAL_START,
    SEARCH_SUBDEAL_BY_ASSOCIATE_START,
    SEARCH_DEALS_BY_MONTH_START,
    CLEAN_SEARCH_LIST_RESPONSE,
    SEARCH_TEXT_IN_DEAL,
    EDIT_DEAL_START,
} from '../../consts/actionTypes';

export const cleanListResponse = () => ({
    type: CLEAN_SEARCH_LIST_RESPONSE,
});
export const searchDeal = (payload) => ({
    type: SEARCH_DEAL_START,
    payload,
});
export const searchSubDealByAssociate = (payload) => ({
    type: SEARCH_SUBDEAL_BY_ASSOCIATE_START,
    payload,
});
export const searchDealByMonth = (payload) => ({
    type: SEARCH_DEALS_BY_MONTH_START,
    payload,
});
export const changeTextDealAction = (keyText, valueText,editText) => ({
    type: SEARCH_TEXT_IN_DEAL,
    keyText,
    valueText,
    editText,
});
export const editDealAction = (payload) => ({
    type: EDIT_DEAL_START,
    payload,
});
