import React from 'react';
import { useStyles } from './styles';
import {
    Paper,
    Tabs,
    Tab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
} from '@material-ui/core';
import {
    SearchSubDeals,
    Details,
    TotalsSubDeals,
    SubDealDetails,
    UnitAdjustment,
} from './components';
import { DinamicTable, SelectDropDown } from '../../components';
import { months, years } from '../../consts/date';

const TabPanel = (props) => {
    const { children, value, index } = props;

    return <div>{value === index && children}</div>;
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
export default (props) => {
    const {
        onRowAdd,
        onRowDelete,
        onRowEdit,
        indexTab,
        handleChangeTab,
        openTabDetail,
        openDetail,
        dialogMessageData,
        handleCloseDialog,
        openMessageConfirmDelete,
        onDeleteSubDeal,
        dataRevert,
        onChangeDataRevert,
        typeAssociate,
        handleChangeRadio,
        dataSearchDeals,
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SearchSubDeals typeAssociate={typeAssociate} handleChangeRadio={handleChangeRadio} />
            <Dialog
                open={dialogMessageData.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
            >
                <DialogTitle>{dialogMessageData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessageData.text}</DialogContentText>
                    {dialogMessageData.type == 'r' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', width: 400 }}>
                            <SelectDropDown
                                labelName={'Month'}
                                nameSelect={'month'}
                                valueProp={dataRevert.month}
                                onChangeProp={onChangeDataRevert}
                                objectList={months}
                                keyValue={'code'}
                                keyLabel={'name'}
                            />
                            <SelectDropDown
                                labelName={'Year'}
                                nameSelect={'year'}
                                valueProp={dataRevert.year}
                                onChangeProp={onChangeDataRevert}
                                objectList={years}
                                keyValue={'code'}
                                keyLabel={'name'}
                            />
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={onDeleteSubDeal}>
                        {dialogMessageData.type == 'd' ? 'Delete' : 'Reverse'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper className={classes.containerTabs}>
                <Tabs
                    value={indexTab}
                    onChange={handleChangeTab}
                    aria-label='simple tabs example'
                    // disabled={typeAssociate === '2'}
                >
                    <Tab label='Sheet' className={classes.tabs} />
                    <Tab
                        label="New Car"
                        className={classes.tabs}
                        disabled={typeAssociate === '2'}
                    />
                    <Tab
                        label="Used Car"
                        className={classes.tabs}
                        disabled={typeAssociate === '2'}
                    />
                    <Tab
                        label='Adjustment deal'
                        className={classes.tabs}
                        // disabled={typeAssociate === '2'}
                    />
                    <Tab
                        label='Details'
                        className={classes.tabs}
                        disabled={typeAssociate === '2'}
                        dataSearchDeals={dataSearchDeals}
                    />
                    {/* <Tab label="Totals" className={classes.tabs} disabled={typeAssociate === '2'} /> */}
                    <Tab label="Edit Sub deal" className={classes.tabs} disabled={openTabDetail} />
                </Tabs>
            </Paper>

            <TabPanel value={indexTab} index={0}>
                <div>
                    <DinamicTable
                        reducer={'washoutReducer'}
                        object={'dinamicTableConf'}
                        isGrouping={true}
                        editable={false}
                        openDetail={openDetail}
                        openMessageConfirmDelete={openMessageConfirmDelete}
                        isTM={typeAssociate === '2'}
                    />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <div>
                    <DinamicTable
                        reducer={'washoutReducer'}
                        object={'dinamicTableConfNew'}
                        isGrouping={true}
                        editable={false}
                        openDetail={openDetail}
                        openMessageConfirmDelete={openMessageConfirmDelete}
                    />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={2}>
                <div>
                    <DinamicTable
                        reducer={'washoutReducer'}
                        object={'dinamicTableConfUsed'}
                        isGrouping={true}
                        editable={false}
                        openDetail={openDetail}
                        openMessageConfirmDelete={openMessageConfirmDelete}
                    />
                </div>
            </TabPanel>

            <TabPanel value={indexTab} index={3}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ maxWidth: 600, marginTop: 10 }}>
                        <DinamicTable
                            reducer={'adjustmentReducer'}
                            object={'associateWashout'}
                            isGrouping={false}
                            onAdd={onRowAdd}
                            onDelete={onRowDelete}
                            onEdit={onRowEdit}
                            editable={true}
                        />
                    </div>
                    <UnitAdjustment />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={4}>
                <div className={classes.content}>
                    <Details typeAssociate={typeAssociate} />
                </div>
            </TabPanel>
            {/* <TabPanel value={indexTab} index={5}>
                <TotalsSubDeals />
            </TabPanel> */}
            <TabPanel value={indexTab} index={5}>
                <SubDealDetails />
            </TabPanel>
        </div>
    );
};
