import React, { useRef, useState, useEffect } from 'react';
import View from './view';

export default (props) => {
    const inputLabelRef = useRef(null);
    const [labelWidthRef, setLabelWidthRef] = useState(0);
    useEffect(() => {
        setLabelWidthRef(inputLabelRef.current.offsetWidth);
    }, []);
    return <View inputLabelRef={inputLabelRef} labelWidthRef={labelWidthRef} {...props} />;
};
