import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flex: 1,
    },
    fab: {
        display: 'block',
        position: 'fixed',
        zIndex: 1,
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

    tabs: {
        color: '#263238',
    },

    containerTabs: {
        marginTop: theme.spacing(2),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: 'white',
    },
    content: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingTop: 5,
    },
    Icons: {
        fontSize: 'larger',
    },
}));
