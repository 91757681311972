export const months = [
    {
        code: 'JAN',
        name: 'January',
    },
    {
        code: 'FEB',
        name: 'February',
    },
    {
        code: 'MAR',
        name: 'March',
    },
    {
        code: 'APR',
        name: 'April',
    },
    {
        code: 'MAY',
        name: 'May',
    },
    {
        code: 'JUN',
        name: 'June',
    },
    {
        code: 'JUL',
        name: 'July',
    },
    {
        code: 'AUG',
        name: 'August',
    },
    {
        code: 'SEP',
        name: 'September',
    },
    {
        code: 'OCT',
        name: 'October',
    },
    {
        code: 'NOV',
        name: 'November',
    },
    {
        code: 'DEC',
        name: 'December',
    },
];

export const years = [];

for (let i = 2019; i <= new Date().getFullYear(); ++i) {
    years.push({
        code: String(i).slice(2),
        name: String(i),
    });
}

years.reverse();
