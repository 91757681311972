import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
    divPrint: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingRight: 25,
        paddingLeft: 25,
        height: '100%',
    },

    field: {
        display: 'flex',
        flexDirection: 'row',
    },
    table: {},
    cell: {
        fontSize: 10,
        padding: 0,
        textAlign: 'right',
        width: '250px',
    },
    bold: {
        fontWeight: 700,
    },
    titleButton: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 0',
        gap: '10px',
    },
    iconButton: {
        marginLeft: 15,
        fontSize: 5,
    },
    textField: {
        marginTop: 10,
    },
    fullWidth: {
        width: '100%',
    },
    contentFit: {
        width: 'fit-content',
    },
}));
